import React from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  avatar: {
    backgroundColor: "#E4E4E4",
    borderRadius: "50%",
    border: "3px solid #E4E4E4",
    width: "100%",
  },
});

const DefaultAvatarIcon = () => {
  const { classes } = useStyles();

  return (
    <svg className={classes.avatar} viewBox="13 -2 88 88" xmlns="http://www.w3.org/2000/svg">
      <g fill="none">
        <path d="M0 0h88v88H0z" />
        <path
          transform="scale(1.3)"
          d="M44 47.667c14.175 0 25.667 11.491 25.667 25.666A3.667 3.667 0 0 1 66 77H22a3.667 3.667 0 0 1-3.667-3.667c0-14.175 11.492-25.666 25.667-25.666zM44 11c8.1 0 14.667 6.566 14.667 14.667 0 8.1-6.567 14.666-14.667 14.666s-14.667-6.566-14.667-14.666C29.333 17.567 35.9 11 44 11z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export default DefaultAvatarIcon;
