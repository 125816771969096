import React from "react";

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import Link from "@/common/components/Link";

import CloseIcon from "@/common/icons/CloseIcon";

import { useHeaderStyles } from "./Header.styles";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<any>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export type MobileNavDialogProps = {
  onClose: () => void;
  navObject: any;
  open: boolean;
};

export const MobileNavDialog = ({ onClose, navObject, open }: MobileNavDialogProps) => {
  const { classes } = useHeaderStyles();

  return (
    <Dialog
      classes={{ paper: classes.mobileDialogRoot }}
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}>
      <div className={classes.mobileDialogContainer}>
        <div>
          <div
            data-cy="LandingPage_mobile_close"
            className={classes.mobileDialogClose}
            onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        {navObject.map((link: any, i: number) => {
          return (
            <div key={i}>
              <Link prefetch={false} href={`${link.link}`}>
                <a>{link.label}</a>
              </Link>
            </div>
          );
        })}
      </div>
    </Dialog>
  );
};
