import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import { withStyles } from "tss-react/mui";

const PrimaryButton = withStyles(Button, (theme: Theme, _props, classes) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
    fontSize: 17,
    color: theme.palette.common.white,
    fontWeight: 800,
    minWidth: 200,
    height: 52,
    borderRadius: 26,
    boxShadow: "none",
    backgroundColor: theme.palette.primary.main,
    [`&.${classes.disabled}`]: {
      color: theme.mode.text.disabled,
      backgroundColor: theme.mode.background.comment,
    },
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.dark,
    },
    disabled: {},
  },
  disabled: {},
}));

export default PrimaryButton;
