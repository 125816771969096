import React, { useEffect, useState } from "react";

import { Box, CircularProgress, Radio, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import Chip from "@/common/components/Chip";
import useCommonStyles from "@/onboarding/components/Common.styles";

import CheckCircledIcon from "@/common/icons/CheckCircledIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useGeoInterestsStore } from "@/common/hooks/use-geo-interests-store";
import { useInterests } from "@/common/hooks/use-interests";

import { Tag } from "@/graphql/types";
import { OnboardingStepProps } from "@/onboarding/models";

import { useOnboardingStyles } from "./Onboarding.styles";
interface OnboardingStep2Props extends OnboardingStepProps {
  interestsChanged: any;
  geoInterestsChanged: (interests: string[]) => Promise<any>;
  ipLocation: string;
}
export const PillSkeletons = ({ numberOfPills }: { numberOfPills: number }) => {
  const { classes } = useOnboardingStyles();
  const renderSkeleton = () =>
    new Array(numberOfPills).fill(null).map((_, index) => {
      return (
        <Skeleton
          key={index}
          animation="wave"
          variant="rectangular"
          width={Math.floor(Math.random() * 150) + 60}
          height={44}
          sx={{ borderRadius: "20px" }}
        />
      );
    });

  return (
    <>
      <div className={classes.pillSkeletonContainer}>{renderSkeleton()}</div>
    </>
  );
};

const OnboardingStep1 = (props: OnboardingStep2Props) => {
  const { classes, cx } = useOnboardingStyles();
  const { session } = useAuthenticationStore();
  const { classes: commonStyles } = useCommonStyles();
  const { t } = useTranslation();

  const { getGeoInterests, availableGeoInterests } = useInterests();

  const [interests, setInterests] = useState<Tag[]>((session?.user?.userInterests as Tag[]) || []);
  const [hasGeoPreselectionOccured, setHasGeoPreselectionOccured] = useState<boolean>(false);
  const [geoInterests, setGeoInterests] = useState<string[]>([]);
  const [hasPreselectedGeoInterest, setHasPreselectedGeoInterest] = useState<boolean>(false);

  const { geoInterestPresets, loading: geoPresetsLoading } = useGeoInterestsStore();
  const [geoInterestsBeforeSelectAll, setGeoInterestsBeforeSelectAll] = useState<string[]>([]);
  const [interestsBeforeSelectAll, setInterestsBeforeSelectAll] = useState<Tag[]>([]);

  useEffect(() => {
    setGeoInterests((session?.user?.geographicalInterests as string[]) || []);
    geoInterestsChanged((session?.user?.geographicalInterests as string[]) || []);

    setInterests((session?.user?.userInterests as Tag[]) || []);
  }, [session]);

  useEffect(() => {
    const updateGeoInterests = async (interests: string[]) => {
      await geoInterestsChanged(interests);
    };

    if (!hasGeoPreselectionOccured && geoInterestPresets) {
      if (
        !hasPreselectedGeoInterest &&
        session?.user?.location?.country &&
        geoInterestPresets.includes(session?.user?.location?.country)
      ) {
        setGeoInterests([session?.user?.location?.country]);
        updateGeoInterests([session?.user?.location?.country]);
        setHasPreselectedGeoInterest(true);
      } else if (!hasPreselectedGeoInterest && geoInterestPresets.includes(props.ipLocation)) {
        setGeoInterests([props.ipLocation]);
        updateGeoInterests([props.ipLocation]);
        setHasPreselectedGeoInterest(true);
      }
      setHasGeoPreselectionOccured(true);
    }
  }, [geoInterestPresets]);

  const { setButtonsState, interestsChanged, geoInterestsChanged } = props;

  const removeInterestsNotForCountries = (countryList: string[]) => {
    if (!countryList) return;
    if (interests) {
      const updatedInterests = interests.filter((selectedInterest) => {
        return countryList.some((x) => selectedInterest.availableFor.includes(x));
      });
      setInterests(updatedInterests);
      interestsChanged(updatedInterests);
    }
  };

  const toggleGeoInterest = (interest: string) => {
    if (geoInterests.length === 1 && geoInterests[0] === interest) {
      return;
    }
    const updatedGeoInterests = geoInterests.includes(interest)
      ? geoInterests.filter((item) => item !== interest)
      : [...geoInterests, interest];

    if (updatedGeoInterests.length > 0) {
      geoInterestsChanged(updatedGeoInterests);
      setGeoInterests(updatedGeoInterests);
    }

    removeInterestsNotForCountries(updatedGeoInterests);
  };

  const selectAllInterests = () => {
    if (interests.length === availableGeoInterests?.length) {
      if (interests.length === interestsBeforeSelectAll.length) {
        setInterests([]);
        interestsChanged([]);
      } else {
        setInterests(interestsBeforeSelectAll);
        interestsChanged(interestsBeforeSelectAll);
      }
    } else {
      if (interests.length > 0) {
        setInterestsBeforeSelectAll(interests);
      }
      availableGeoInterests && setInterests(availableGeoInterests);
      interestsChanged(availableGeoInterests);
    }
  };

  const selectAllGeoInterests = () => {
    if (!geoInterestPresets) return;
    if (geoInterests.length === geoInterestPresets?.length) {
      geoInterestsChanged(geoInterestsBeforeSelectAll);
      setGeoInterests(geoInterestsBeforeSelectAll);
      removeInterestsNotForCountries(geoInterestsBeforeSelectAll);
    } else {
      if (geoInterests.length > 0) {
        setGeoInterestsBeforeSelectAll(geoInterests);
      }
      geoInterestPresets && setGeoInterests(geoInterestPresets as string[]);
      geoInterestsChanged(geoInterestPresets as string[]);
    }
  };
  const toggleInterest = (interest: Tag) => {
    const updatedInterests = interests.map((i) => i.name).includes(interest.name)
      ? interests.filter((item) => item.name !== interest.name)
      : [...interests, interest];

    setInterests(updatedInterests);
    interestsChanged(updatedInterests);
  };

  useEffect(() => {
    getGeoInterests({
      variables: { geographicalInterests: geoInterests.map((value) => value.toUpperCase()) },
    });
  }, [geoInterests]);

  useEffect(() => {
    setButtonsState((prevState: any) => ({
      ...prevState,
      canGoNext: interests.length > 0 && geoInterests.length > 0,
    }));
  }, [interests, geoInterests]);

  const sortedInterests = [...availableGeoInterests].sort((a, b) =>
    t(a.translationKey).toLowerCase().localeCompare(t(b.translationKey).toLowerCase()),
  );

  return (
    <div className={classes.paper}>
      <Grid item container xs={12} className={classes.topInfo}>
        <Grid item md={4}>
          <Typography variant="h5" className={commonStyles.secondaryText}>
            {t("Onboarding.CompleteYourProfile_text")}
          </Typography>
          <Typography variant="h2" gutterBottom className={commonStyles.primaryText}>
            {t("Onboarding.TellUsWhatYouCareAbout")}
          </Typography>
          <Typography variant="h5" gutterBottom className={commonStyles.introText}>
            {t("Onboarding.Step2SelectTopicTags_text")}
          </Typography>
        </Grid>
        <Grid item md={1} />
        <Grid item md={6}>
          {(!hasGeoPreselectionOccured || geoPresetsLoading) && (
            <div className={commonStyles.center}>
              <CircularProgress size={36} />
            </div>
          )}

          {!hasPreselectedGeoInterest && !geoPresetsLoading && (
            <>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                className={classes.boxTitle}
                pt={1}>
                <div>{t("Onboarding.SectionTitles.GeographicalInterests")}&nbsp;*</div>
                <div
                  onClick={() => selectAllGeoInterests()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    fontSize: "0.9375rem",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}>
                  {t("Profile.Edit.EditInterests.SelectAllButton")}
                  <Radio
                    checked={geoInterests.length === geoInterestPresets?.length}
                    disableRipple
                    color="default"
                    checkedIcon={
                      <CheckCircledIcon
                        style={{
                          width: 24,
                          height: 24,
                          color: "white",
                          justifyContent: "space-between",
                        }}
                      />
                    }
                  />
                </div>
              </Box>

              <Box className={classes.flexContainer} pt={1}>
                {geoPresetsLoading && <PillSkeletons numberOfPills={3} />}
                {geoInterestPresets &&
                  geoInterestPresets?.map((geoPreset) => (
                    <Chip
                      className={cx(classes.chip, {
                        [classes.activeChip]:
                          (geoPreset && geoInterests.map((i) => i).includes(geoPreset)) || false,
                      })}
                      color="primary"
                      key={geoPreset}
                      label={t(`GeographicalInterests.${geoPreset}`)}
                      onClick={() => geoPreset && toggleGeoInterest(geoPreset)}
                    />
                  ))}
              </Box>
            </>
          )}

          {geoInterests.length > 0 && (
            <>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                className={classes.boxTitle}
                pt={1}>
                <div>{t("Onboarding.SectionTitles.Interests")}&nbsp;*</div>
                <div
                  onClick={() => {
                    selectAllInterests();
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    fontSize: "0.9375rem",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}>
                  {t("Profile.Edit.EditInterests.SelectAllButton")}
                  <Radio
                    checked={
                      interests.length === availableGeoInterests?.length &&
                      sortedInterests.length > 0
                    }
                    disableRipple
                    color="default"
                    checkedIcon={
                      <CheckCircledIcon
                        style={{
                          width: 24,
                          height: 24,
                          color: "white",
                          justifyContent: "space-between",
                        }}
                      />
                    }
                  />
                </div>
              </Box>

              <Box className={classes.flexContainer} pt={1}>
                {sortedInterests.length === 0 && <PillSkeletons numberOfPills={23} />}
                {sortedInterests.map((interest) => (
                  <Chip
                    className={cx(classes.chip, {
                      [classes.activeChip]: interests.map((i) => i.name).includes(interest.name),
                    })}
                    color="primary"
                    key={interest.name}
                    label={t(interest.translationKey)}
                    onClick={() => toggleInterest(interest)}
                  />
                ))}
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={12}></Grid>
    </div>
  );
};

export default OnboardingStep1;
