import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { MOBILE_PLAYSTORE_URL } from "../constants";
import { localizedAppstoreURL } from "../services";

const useDetectPlatform = () => {
  const [platform, setPlatform] = useState("");
  const { i18n } = useTranslation();

  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      setPlatform("android");
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      setPlatform("ios");
    } else {
      setPlatform("other");
    }
  }, []);

  const handleRedirectToStore = () => {
    switch (platform) {
      case "android":
        window.location.replace(MOBILE_PLAYSTORE_URL);
        break;
      case "ios":
        window.location.replace(localizedAppstoreURL(i18n.language));
        break;
      default:
        break;
    }
  };

  return { platform, handleRedirectToStore };
};

export default useDetectPlatform;
