import React from "react";

import { useTheme } from "@mui/material/styles";

function HorizontalGradient(props: any) {
  const theme = useTheme();
  const { main } = theme.palette.primary;

  return (
    <linearGradient id={props.id} x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stopColor={main} />
      <stop offset="0.509" stopColor={main} />
      <stop offset="1" stopColor={main} />
    </linearGradient>
  );
}

export default HorizontalGradient;
