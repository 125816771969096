import {
  DeleteMessageMutationVariables,
  ShareMessageMutationVariables,
  LikeMessageMutationVariables,
  UnlikeMessageMutationVariables,
  GetLikedByQueryVariables,
  Likeable,
  useDeleteMessageMutation,
  useShareMessageMutation,
  useLikeMessageMutation,
  useUnlikeMessageMutation,
  useGetLikedByLazyQuery,
  useEditMessageMutation,
  EditMessageMutationVariables,
  ChatMessagesQuery,
  ChatMessagesQueryVariables,
  ChatMessagesDocument,
} from "@/graphql/types";

export const useChatMessageStore = () => {
  const [deleteMessage, { loading: loadingDeleteMessage }] = useDeleteMessageMutation();
  const [editMessage] = useEditMessageMutation();
  const [forwardMessage, { loading: loadingForwardMessage }] = useShareMessageMutation();
  const [likeMessage] = useLikeMessageMutation();
  const [unlikeMessage, { loading: loadingUnlikeMessage }] = useUnlikeMessageMutation();
  const [fetchLikedByUsers, { loading: loadingLikedBy, data, fetchMore, refetch }] =
    useGetLikedByLazyQuery({
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    });

  return {
    loadingDeleteMessage,
    loadingForwardMessage,
    loadingUnlikeMessage,
    loadingLikedBy,
    likedByUsers: data?.likedBy?.edges?.map(({ node }) => node) || [],
    hasMoreLikedByUsers: data?.likedBy.pageInfo.hasNextPage || false,
    deleteMessage({ id }: DeleteMessageMutationVariables) {
      return deleteMessage({
        variables: { id },
      });
    },
    editMessage({ id, newBody }: EditMessageMutationVariables) {
      return editMessage({
        variables: { id, newBody },
        update(cache, { data }) {
          if (data?.editMessage?.successful) {
            const result = cache.readQuery<ChatMessagesQuery, ChatMessagesQueryVariables>({
              query: ChatMessagesDocument,
              variables: { limit: 20, chatId: data.editMessage.result?.chatId },
            });

            if (result) {
              cache.writeQuery({
                query: ChatMessagesDocument,
                variables: {},
                data: {
                  ...result,
                  allChatMessages: {
                    ...result.allChatMessages,
                    edges: result.allChatMessages.edges.map((edge) => {
                      if (edge.node.id === id) {
                        return {
                          ...edge,
                          node: { ...edge.node, body: newBody },
                        };
                      }
                      return edge;
                    }),
                  },
                },
              });
            }
          }
        },
      });
    },
    likeMessage({ id }: LikeMessageMutationVariables) {
      return likeMessage({ variables: { id } });
    },
    unlikeMessage({ id }: UnlikeMessageMutationVariables) {
      return unlikeMessage({ variables: { id } });
    },
    forwardMessage({ messageId, destinationChatId }: ShareMessageMutationVariables) {
      return forwardMessage({
        variables: { messageId, destinationChatId },
      });
    },
    fetchLikedByUsers({ id }: Pick<GetLikedByQueryVariables, "id">) {
      return fetchLikedByUsers({ variables: { id, limit: 10, type: Likeable.Message } });
    },
    fetchMoreLikedByUsers() {
      if (fetchMore && data?.likedBy) {
        return fetchMore({
          variables: {
            after: data.likedBy.pageInfo.endCursor,
            limit: 10,
          },
        });
      }
    },
    refetchLikedByUsers() {
      return refetch && refetch();
    },
  };
};
