import React from "react";

import { useMediaQuery, Theme } from "@mui/material";

import TopbarDesktop from "@/common/components/TopBarDesktop";
import { TopbarMobile } from "@/common/components/TopBarMobile/TopBarMobile";

import { AppBarActions } from "@/common/models/AppBarActions";
import { NamedRoute } from "@/common/models/NamedRoute";

export interface TopbarProps {
  tabs?: React.ReactNode;
  onActionClicked: (action: AppBarActions) => void;
  breadcrumbs?: NamedRoute;
  showMinimal?: boolean;
  coloured?: boolean;
  searchAlwaysOpen?: boolean;
  formId?: string;
  isCreateButtonDisabled?: boolean;
  actionButtonText?: string;
  headingLabel?: string;
  isActionLoading?: boolean;
  showLanguageSwitcher?: boolean;
}

function Topbar(props: TopbarProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return isMobile ? <TopbarMobile {...props} /> : <TopbarDesktop {...props} />;
}

export default Topbar;
