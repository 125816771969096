import {
  RenameChatMutationVariables,
  InviteChatMembersMutationVariables,
  RemoveChatMemberMutationVariables,
  AgreeChatBlockingMutationVariables,
  UserFragment,
  useChatQuery,
  useRenameChatMutation,
  useInviteChatMembersMutation,
  useRemoveChatMemberMutation,
  useAgreeChatBlockingMutation,
} from "@/graphql/types";

type LastSeenMessagesMapType = { [key: string]: UserFragment[] };

export const useChatStore = (chatId?: string) => {
  const { loading, data: chatData } = useChatQuery({
    variables: { chatId },
    skip: !chatId,
  });

  const [renameChat, { loading: loadingRenameChat }] = useRenameChatMutation();
  const [inviteChatMembers, { loading: loadingInviteChatMembers }] = useInviteChatMembersMutation();
  const [removeChatMember, { loading: loadingRemoveChatMember }] = useRemoveChatMemberMutation();
  const [agreeChatBlocking] = useAgreeChatBlockingMutation();

  return {
    loading,
    loadingRenameChat,
    loadingInviteChatMembers,
    loadingRemoveChatMember,
    chat: chatData?.chat || undefined,
    lastSeenMessagesMap: (chatData?.chat?.lastSeenMessages || []).reduce<LastSeenMessagesMapType>(
      (messagesMap, lastSeenMessage) => {
        messagesMap[lastSeenMessage.id] =
          (chatData?.chat?.otherMembers || []).filter(({ id }) =>
            lastSeenMessage.seenBy.includes(id),
          ) || [];

        return messagesMap;
      },
      {},
    ),
    renameChat({ name, id }: RenameChatMutationVariables) {
      return renameChat({
        variables: {
          id,
          name,
        },
      });
    },
    inviteChatMembers({ id, userIds }: InviteChatMembersMutationVariables) {
      return inviteChatMembers({ variables: { id, userIds } });
    },
    removeChatMember({ id, userId }: RemoveChatMemberMutationVariables) {
      return removeChatMember({ variables: { id, userId } });
    },
    agreeChatBlocking({ chatId, userIds }: AgreeChatBlockingMutationVariables) {
      return agreeChatBlocking({
        variables: { chatId, userIds },
      });
    },
  };
};
