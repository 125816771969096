import { useRouter } from "next/router";
import React, { useEffect, useState, useRef } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, FilledInput, Autocomplete, Link, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";

import { Button } from "@/common/components/button/Button";
import NothingToShow from "@/common/components/nothing-to-show/NothingToShow";
import { ResultItemIcon } from "@/search/components/ResultItemIcon/ResultItemIcon";

import SearchIcon from "@/common/icons/SearchIcon";

import { useSearchBarStore } from "@/search/hooks";

import { SearchResultFragment } from "@/graphql/types";
import { renderResultTitleText } from "@/search/services";

import { EmptySearchPlaceholder } from "./EmptySearchPlaceholder";
import useStyles from "./SearchBar.styles";

export type SearchBarProps = {
  closeButtonClicked?: () => void;
  height?: number;
  alwaysOpen?: boolean;
};

const SearchBar = ({ height, closeButtonClicked, alwaysOpen }: SearchBarProps) => {
  const { classes, cx } = useStyles({ height });

  const textInput = useRef<HTMLInputElement>();
  const { t } = useTranslation();
  const {
    results,
    search,
    count,
    hasMore,
    loading,
    recentSearches,
    removeSearchResultLoading,
    logRecentSearch,
    clearSearchResult,
  } = useSearchBarStore();
  const router = useRouter();

  const { f } = router.query;

  useEffect(() => {
    const fValue = Array.isArray(f) ? f[0] : f;
    if (f && fValue == "1") {
      textInput.current?.select();
    }
  }, [f]);

  const [query, setQuery] = useState(
    Array.isArray(router.query.q) ? router.query.q[0] : router.query.q || "",
  );

  useEffect(() => {
    if (!alwaysOpen) textInput.current?.focus();
  }, []);

  const handleSelectionChanged = (_event: unknown, newValue: SearchResultFragment | null) => {
    if (newValue === null) {
      return;
    }

    logRecentSearch(newValue);

    newValue.url && router.push(newValue.url);
  };

  const showAllSearchResults = () => {
    textInput.current?.blur();
    if (closeButtonClicked) closeButtonClicked();
    router.replace(`/search?q=${query}`);
  };

  const showRecents = query.length == 0;

  const firstResultText = renderResultTitleText(results[0]);
  const autosuggestion =
    query.length > 0 && firstResultText.startsWith(query) ? firstResultText : "";

  const handleClear = () => {
    clearSearchResult();
  };

  const showHeader = () => {
    if (query.length === 0) {
      if (recentSearches.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (results.length == 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  const debouncedRouteChange = debounce(
    (value: string) => router.replace(`/search?q=${value}`),
    150,
  );

  const handleInputChange = (value: string) => {
    setQuery(value);
    if (alwaysOpen) {
      debouncedRouteChange(value);
    } else {
      search(value);
    }
  };

  return (
    <Box display="flex" className={classes.container}>
      <form
        className={classes.container}
        onSubmit={(e) => {
          e.preventDefault();
          showAllSearchResults();
        }}>
        <SearchIcon className={classes.searchIcon} onClick={showAllSearchResults} />

        <Autocomplete
          id="search"
          inputValue={query}
          fullWidth={true}
          getOptionLabel={renderResultTitleText}
          filterOptions={(option) => option}
          // @ts-ignore
          options={showRecents && recentSearches.length > 0 ? recentSearches : results}
          autoComplete
          autoHighlight
          includeInputInList
          filterSelectedOptions
          openOnFocus
          onChange={handleSelectionChanged}
          onInputChange={(_event, value) => handleInputChange(value)}
          classes={{
            paper: classes.paper,
            listbox: classes.listbox,
            noOptions: classes.noOptions,
          }}
          noOptionsText={
            <>
              {query.length === 0 && recentSearches.length == 0 ? (
                <EmptySearchPlaceholder />
              ) : (
                <div>
                  <NothingToShow
                    footerElements={[]}
                    title={t("Home.Search.NoResults")}
                    subtitle=""
                    image={<SearchIcon />}
                    smallHeight
                  />
                </div>
              )}
            </>
          }
          clearOnBlur={false}
          clearOnEscape={true}
          open={!alwaysOpen}
          renderInput={(params) => (
            <FilledInput
              {...params}
              ref={params.InputProps.ref}
              inputProps={{
                ...params.inputProps,
                "data-cy": "SearchBar__field",
              }}
              inputRef={textInput}
              margin="dense"
              placeholder={t("App.Search.SearchField.Hint")}
              disableUnderline
              data-autosuggestion={autosuggestion}
              className={classes.searchInput}
              classes={{ input: classes.searchInputRoot }}
            />
          )}
          PaperComponent={({ children, ...props }) => (
            <div {...props}>
              {loading ? (
                <div className={classes.loadingWrapper}>
                  <CircularProgress size={32} />
                </div>
              ) : (
                <div data-cy="SearchBar__recent-results">
                  {showHeader() && (
                    <div className={classes.resultHeader}>
                      <span className={classes.resultTitle}>
                        {showRecents && recentSearches.length > 0
                          ? t("Home.Search.Recent")
                          : t("App.Search.TopResults")}
                      </span>
                      {showRecents && recentSearches.length > 0 && (
                        <Link
                          classes={{ root: classes.resultHeaderAction }}
                          component="button"
                          underline="none"
                          disabled={removeSearchResultLoading}
                          onClick={handleClear}>
                          {t("Generic.Clear")}
                        </Link>
                      )}
                    </div>
                  )}
                  {children}
                  {!showRecents && hasMore && (
                    <Button className={classes.seeAll} onClick={showAllSearchResults}>
                      {t("App.Search.SeeAllResults", { number: count }).toUpperCase()}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
          renderOption={(props, option) => (
            <li
              key={`${option.__typename}_${option.id}`}
              {...props}
              className={cx(props.className, classes.resultItem)}>
              <ResultItemIcon item={option} />
              <p className={classes.resultItemTitle} data-cy="SearchBar__result-entity-title">
                {renderResultTitleText(option)}
              </p>
            </li>
          )}
        />

        {!alwaysOpen && (
          <IconButton onClick={closeButtonClicked} edge="end" size="large">
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        )}
      </form>
    </Box>
  );
};

export default SearchBar;
