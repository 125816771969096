import React, { ReactNode } from "react";

import { Avatar, AvatarProps, Badge } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { maybeGetCdnPath } from "@/common/services";
import { UserFullFragment } from "@/graphql/types";

import { palettes } from "../constants";
import DefaultAvatarIcon from "../icons/DefaultAvatarIcon";

const useStyles = makeStyles()((theme) => ({
  badge: {
    backgroundColor: palettes.green.primary.dark,
    zIndex: 10,
    width: 12,
    height: 12,
    borderRadius: "50%",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.mode.background.light,
  },
  badgeContent: {
    width: "unset",
    height: "unset",
    backgroundColor: "unset",
    border: "none",
    cursor: "pointer",
  },
}));

type UserAvatarProps = {
  user?: Pick<
    UserFullFragment,
    | "avatar"
    | "isPresent"
    | "themeColor"
    | "avatarPredefinedName"
    | "deleted"
    | "onboarded"
    | "hideOnlinePresence"
  > | null;
  hidePresence?: boolean;
  defaultAvatar?: ReactNode;
  isAnonymous?: boolean;
  badgeContent?: ReactNode;
  alt?: string;
  badgeStyles?: React.CSSProperties;
  onBadgeClick?: () => void;
} & AvatarProps;

const UserAvatar = ({
  user,
  hidePresence,
  defaultAvatar,
  isAnonymous,
  badgeContent,
  alt = "",
  badgeStyles,
  onBadgeClick,
  ...props
}: UserAvatarProps) => {
  const { classes, cx } = useStyles();
  const { avatarPredefinedName, avatar } = user || {};
  const avatarSrc = avatarPredefinedName
    ? maybeGetCdnPath(`/images/avatars/${avatarPredefinedName}.png`)
    : avatar?.thumb;
  const displayAvatar = !(isAnonymous || user?.deleted);

  return (
    <Badge
      classes={{ badge: cx(classes.badge, { [classes.badgeContent]: !!badgeContent }) }}
      componentsProps={{
        badge: {
          style: badgeStyles,
        },
      }}
      {...(badgeContent ? { badgeContent } : { variant: "dot" })}
      onClick={onBadgeClick}
      overlap="circular"
      invisible={hidePresence || isAnonymous || !user?.isPresent || !!user?.hideOnlinePresence}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
      <Avatar
        classes={props.classes}
        src={displayAvatar ? avatarSrc : undefined}
        alt={alt}
        {...props}>
        {defaultAvatar || <DefaultAvatarIcon />}
      </Avatar>
    </Badge>
  );
};

export default UserAvatar;
