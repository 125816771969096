import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme: Theme) => ({
  dialog: {
    borderRadius: 24,
    width: "612px",
    maxHeight: "350px",
  },
  container: {
    width: "100%",
    height: "calc(100vh - 175px)",

    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 250px)",
    },

    "& .center": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        padding: "0px 18px",
      },

      "& .icon svg": {
        width: "56px",
        height: "56px",
        marginBottom: "24px",
      },

      "& .title": {
        color: theme.mode.text.description,
        fontSize: "1.0625rem",
        fontWeight: "bold",
        lineHeight: "1.29",
        marginBottom: "8px",
      },

      "& .subtitle": {
        color: theme.mode.text.description,
        fontSize: "0.9375rem",
        lineHeight: "1.47",
        marginBottom: "12px",
        "& span": {
          maxWidth: 462,
          display: "block",
          margin: "0 auto",
        },
      },

      "& .cta button": {
        margin: "0 auto",
      },
    },
  },
}));
