import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useNothingToShowStyles = makeStyles()((theme: Theme) => {
  return {
    wrapper: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: theme.spacing(4, 0),
      height: "65vh",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(4),
      },
    },
    smallHeight: {
      height: "25vh",
    },
    image: {
      width: "30vw",
      textAlign: "center",
      "& svg": {
        width: "40px",
        height: "40px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "25vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "15vw",
      },
    },
    title: {
      fontSize: "1.0625rem",
      lineHeight: "1.29",
      fontWeight: "bold",
      color: theme.mode.icon.default,
      marginTop: "1rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.3125rem",
        marginTop: "2rem",
      },
    },
    subtitle: {
      fontSize: "0.9375rem",
      fontWeight: "normal",
      color: theme.mode.icon.default,
      maxWidth: "380px",
      textAlign: "center",
      marginTop: "10px",
      lineHeight: "normal",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.0625rem",
        marginTop: "0.5rem",
      },
    },
    nav: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > *": {
        marginTop: "1.5rem",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    link: {
      fontSize: "1.0625rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.3125rem",
      },
    },
    button: {
      fontSize: "1.0625rem",
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    footerText: {
      fontSize: "1.3125rem",
      fontWeight: "normal",
      color: "#7d7d8c",
      maxWidth: "380px",
      textAlign: "center",
      lineHeight: "normal",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.0625rem",
      },
    },
    footerTextButton: {
      margin: 5,
      textTransform: "uppercase",
      fontWeight: "800",
      fontSize: "1.0625rem",
      lineHeight: "1.29",
    },
    orSeparator: {
      color: theme.mode.text.description,
      fontSize: "0.9375rem",
    },
    noSpacing: {
      margin: 0,
      padding: 0,
    },
  };
});
