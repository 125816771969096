import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ height: number | undefined }>()((theme: Theme, { height }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  searchIcon: {
    position: "absolute",
    marginLeft: 16,
    width: "28px",
    height: "28px",
    zIndex: 1,
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
  },
  closeIcon: {
    width: "1.5em",
    height: "1.5em",
    color: theme.mode.icon.default,
  },

  searchInput: {
    fontWeight: 600,
    color: theme.mode.text.heading,
    letterSpacing: "0.01em",
    fontSize: 18,
    borderRadius: 21,
    backgroundColor: theme.mode.background.input,
    position: "relative",
    padding: "12px 12px 12px 65px !important",

    "&:before": {
      content: "attr(data-autosuggestion)",
      position: "absolute",
      color: "#00000055",
    },
  },
  searchInputRoot: { padding: "0 !important" },
  paper: {
    height: height,
    marginTop: theme.spacing(1),
    boxShadow: theme.mode.shadow.bigDialog,
    borderRadius: theme.spacing(2),
    backgroundColor: theme.mode.background.light,

    [theme.breakpoints.down("sm")]: {
      height: 500,
    },
  },
  listbox: {
    padding: 0,
    maxHeight: "80vh",
    overflowX: "clip",
  },
  noOptions: {
    color: `${theme.mode.text.description} !important`,
    margin: theme.spacing(1),
    fontWeight: "normal",
    fontSize: "0.9375rem",
    lineHeight: "1.47",
  },
  loadingWrapper: {
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  resultHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(3, 2.5, 1.5),
  },
  resultTitle: {
    fontWeight: 900,
    fontSize: "1.065rem",
    color: theme.mode.text.heading,
  },
  resultHeaderAction: {
    fontSize: "1.0625rem",
    fontWeight: 800,
  },
  seeAll: {
    fontSize: "0.9375rem",
    fontWeight: 800,
    margin: theme.spacing(1.5, 0, 2.5, 3),
    display: "block",
  },

  resultItem: {
    width: "100%",
    padding: `${theme.spacing(1.5, 2.75)} !important`,
    margin: theme.spacing(0.5, 0.25),
  },
  resultItemTitle: {
    color: theme.mode.text.title,
    fontSize: "1.0625rem",
    fontWeight: 600,
    marginLeft: theme.spacing(1.5),
  },
}));

export default useStyles;
