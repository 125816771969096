import React, { HTMLAttributes } from "react";

function ChatIcon(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="chat">
          <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
          <path
            d="M18.3111359,5.64803464 C15.2795883,2.59213117 10.5070263,2.125983 6.94303718,4.53768378 C3.3790481,6.94938456 2.03015173,11.5578367 3.73000676,15.5148776 C3.8158907,15.6931654 3.84407033,15.8938534 3.81061497,16.0889484 L3.02244583,19.883198 C2.96076168,20.1787027 3.05164216,20.4854615 3.26427045,20.699455 C3.43766148,20.8718459 3.67384749,20.9658135 3.91809258,20.9595809 L4.09722193,20.9595809 L7.93059002,20.1881732 C8.12541992,20.1647136 8.32299069,20.1925385 8.50380394,20.2689019 C12.454938,21.9712982 17.0565109,20.6203852 19.4646116,17.0510681 C21.8727123,13.4817509 21.40726,8.702054 18.3559182,5.66597436 L18.3111359,5.64803464 Z"
            id="Chat"
            fill="currentColor"></path>
        </g>
      </g>
    </svg>
  );
}

export default ChatIcon;
