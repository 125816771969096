import React, { useState, CSSProperties } from "react";

import { Close } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import Chip from "@/common/components/Chip";
import PrimaryButton from "@/common/components/PrimaryButton";

import { useProfileStore } from "@/profile/hooks";

import { useSnackbarContext } from "@/common/providers";
import { lighten } from "@/common/services";
import { Tag } from "@/graphql/types";

import { useSelectableTags } from "../hooks/use-selectable-tags";

const useStyles = makeStyles()((theme: Theme) => {
  const action = {
    fontWeight: 700,
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  } as CSSProperties;

  return {
    dialog: {
      borderRadius: "32px",
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
      },
      backgroundColor: theme.mode.background.light,
    },
    header: {
      paddingTop: theme.spacing(3),
    },
    titleWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    title: {
      fontSize: "1.3125rem",
      color: theme.mode.text.heading,
    },
    closeBtn: {
      padding: 0,
      height: "1em",
      color: theme.mode.icon.default,
      transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        color: theme.mode.icon.light,
      },
    },
    actionCancel: {
      ...action,
      textTransform: "none",
      fontSize: "1.3125rem",
      color: "#bebec5",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.93rem",
      },
    },
    actionUpdate: {
      ...action,
    },
    chip: {
      backgroundColor: lighten(theme.palette.primary.light, 0.2),
      color: theme.palette.primary.main,
    },
    activeChip: {
      color: `${theme.palette.common.white} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    contentRoot: {
      justifyContent: "left",
      flexWrap: "wrap",
      boxSizing: "border-box",
      padding: theme.spacing(1, 2),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1, 0.75),
      },
      "& > *": {
        margin: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0.75),
        },
      },
    },
    actionsRoot: {
      paddingTop: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
        "& > :not(:first-of-type)": {
          marginLeft: 0,
        },
      },
    },
    buttonProgress: {},
  };
});

interface InterestsEditPopupProps {
  open: boolean;
  onClose: (res: any) => void;
  selectedTags: Tag[];
  tags: Tag[];
}

const InterestsEditPopup = (props: InterestsEditPopupProps) => {
  const { loading, update: updateProfile } = useProfileStore();
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [disableDoneBtn, setDisableDoneBtn] = useState(true);
  const { open: openSnackbar } = useSnackbarContext();

  const { activeItems, toggle, items } = useSelectableTags(
    props.tags.map((interest) => ({
      text: t(interest.translationKey),
      value: interest.name,
    })),
    props.selectedTags.map((i) => ({ text: t(i.translationKey), value: i.name })),
    { min: 1 },
  );

  React.useEffect(() => {
    setDisableDoneBtn(true);
  }, [props.open]);

  const toggleInterest = (name: string) => {
    toggle(name);
    setDisableDoneBtn(false);
  };

  const onDoneClick = async () => {
    await updateProfile(
      {
        userInterests: activeItems,
      },
      false,
    );
    openSnackbar({
      open: true,
      message: t("Profile.Edit.EditInterestsPopup.WeAreUpdatingYourContent"),
    });
    props.onClose(null);
  };

  return (
    <Dialog
      fullScreen={isMobile}
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      classes={{ paper: classes.dialog }}>
      <DialogTitle classes={{ root: classes.header }}>
        <div className={classes.titleWrapper}>
          <span className={classes.title}>{t("Profile.RightColumn.EditInterests")}</span>
          <IconButton
            aria-label="close"
            className={classes.closeBtn}
            onClick={props.onClose}
            size="large">
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRoot }}>
        {items
          .slice()
          .sort((a, b) => {
            var nameA = a.text.toLowerCase();
            var nameB = b.text.toLowerCase();
            return nameA.localeCompare(nameB);
          })
          .map((interest) => (
            <Chip
              className={cx(classes.chip, {
                [classes.activeChip]: interest.active,
              })}
              color="primary"
              key={interest.value}
              label={interest.text}
              onClick={() => toggleInterest(interest.value)}
            />
          ))}
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsRoot }}>
        <Button variant="text" onClick={props.onClose}>
          <span className={classes.actionCancel}>{t("Generic.Cancel")}</span>
        </Button>
        <PrimaryButton
          onClick={onDoneClick}
          disabled={loading || disableDoneBtn}
          className={classes.actionUpdate}>
          {loading ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : (
            t("Generic.Update")
          )}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default InterestsEditPopup;
