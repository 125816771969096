import { useEffect, useState } from "react";

import { useSubscription } from "@apollo/client";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import {
  UserChatsQuery,
  UserChatsQueryVariables,
  UserChatsDocument,
  ChatInput,
  DeleteChatMutationVariables,
  LeaveChatMutationVariables,
  ClearChatMutationVariables,
  ReportChatMutationVariables,
  ChatUpdateDocument,
  ChatListChangedDocument,
  RootSubscriptionType,
  useUserChatsQuery,
  useCreateChatMutation,
  useDeleteChatMutation,
  useLeaveChatMutation,
  useClearChatMutation,
  useReportChatMutation,
  MuteChatMutationVariables,
  UnmuteChatMutationVariables,
  useMuteChatMutation,
  useUnmuteChatMutation,
} from "@/graphql/types";

export const useChatsStore = (params?: { noCache: boolean }) => {
  const {
    loading: loadingChats,
    data: chatsData,
    subscribeToMore,
    fetchMore: fetchMoreUserChats,
    refetch,
  } = useUserChatsQuery({
    variables: { limit: 20 },
    fetchPolicy: params?.noCache ? "no-cache" : "cache-and-network",
    nextFetchPolicy: params?.noCache ? "no-cache" : "cache-first",
  });
  const [muteChat, { loading: loadingMuteChat }] = useMuteChatMutation();
  const [unmuteChat, { loading: loadingUnmuteChat }] = useUnmuteChatMutation();
  const [createChat, { loading: loadingCreateChat }] = useCreateChatMutation();
  const [deleteChat, { loading: loadingDeleteChat }] = useDeleteChatMutation();
  const [leaveChat, { loading: loadingLeaveChat }] = useLeaveChatMutation();
  const [clearChat] = useClearChatMutation();
  const [reportChat] = useReportChatMutation();

  useSubscription(ChatListChangedDocument, {
    skip: !chatsData,
    onSubscriptionData() {
      refetch && refetch();
    },
  });

  const [searchStream$] = useState(new Subject<string | undefined>());

  useEffect(() => {
    const subscription = searchStream$
      .pipe(debounceTime(125))
      .subscribe((query) => refetch({ query }));

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: ChatUpdateDocument,
      updateQuery: (prev, { subscriptionData }) => {
        const chat = (subscriptionData.data as RootSubscriptionType)?.chatUpdate;

        if (!chat) return prev;

        return Object.assign({}, prev, {
          userChats: {
            ...prev.userChats,
            edges: [
              {
                __typename: "ChatEdge",
                node: chat,
              },
              ...prev.userChats.edges.filter(({ node }) => node.id !== chat.id),
            ],
          },
        });
      },
    });

    return () => {
      unsubscribe && unsubscribe();
    };
  }, []);

  return {
    loadingChats,
    loadingCreateChat,
    loadingDeleteChat,
    loadingLeaveChat,
    loadingMuteChat,
    loadingUnmuteChat,
    chats:
      chatsData?.userChats.edges
        .map(({ node }) => node)
        .sort((a, b) => new Date(b.lastActionAt).getTime() - new Date(a.lastActionAt).getTime()) ||
      [],
    hasMoreChats: chatsData?.userChats.pageInfo.hasNextPage || false,
    fetchNextUserChatsPage() {
      if (fetchMoreUserChats && chatsData) {
        return fetchMoreUserChats({
          variables: {
            after: chatsData.userChats.pageInfo.endCursor,
          },
        });
      }
    },
    refetchWithQuery(query?: string) {
      searchStream$.next(query);
    },
    muteChat({ chatId, muteChatDate, muteChatIndefinitely }: MuteChatMutationVariables) {
      return muteChat({
        variables: {
          chatId,
          muteChatDate,
          muteChatIndefinitely,
        },
      });
    },
    unmuteChat({ chatId }: UnmuteChatMutationVariables) {
      return unmuteChat({
        variables: {
          chatId,
        },
      });
    },
    createChat(data: ChatInput) {
      return createChat({
        variables: { data },
        update(cache, { data }) {
          if (data?.createChat) {
            const result = cache.readQuery<UserChatsQuery, UserChatsQueryVariables>({
              query: UserChatsDocument,
              variables: { limit: 20 },
            });

            if (
              result &&
              !result.userChats.edges.map(({ node }) => node.id).includes(data.createChat.id)
            ) {
              cache.writeQuery({
                query: UserChatsDocument,
                variables: {
                  variables: { limit: 20 },
                },
                data: {
                  ...result,
                  userChats: {
                    ...result.userChats,
                    edges: [
                      {
                        __typename: "ChatEdge",
                        node: data.createChat,
                      },
                      ...result.userChats.edges,
                    ],
                  },
                },
              });
            }
          }
        },
      });
    },
    deleteChat({ id }: DeleteChatMutationVariables) {
      return deleteChat({
        variables: { id },
        update(cache, { data }) {
          if (data?.deleteChat) {
            const result = cache.readQuery<UserChatsQuery, UserChatsQueryVariables>({
              query: UserChatsDocument,
              variables: { limit: 20 },
            });

            if (result) {
              cache.writeQuery({
                query: UserChatsDocument,
                variables: {
                  variables: { limit: 20 },
                },
                data: {
                  ...result,
                  userChats: {
                    ...result.userChats,
                    edges: result.userChats.edges.filter(
                      ({ node }) => node.id !== data.deleteChat?.result?.id,
                    ),
                  },
                },
              });
            }
          }
        },
      });
    },
    leaveChat({ id }: LeaveChatMutationVariables) {
      return leaveChat({
        variables: { id },
        update(cache, { data }) {
          if (data?.leaveChat) {
            const result = cache.readQuery<UserChatsQuery, UserChatsQueryVariables>({
              query: UserChatsDocument,
              variables: { limit: 20 },
            });

            if (result) {
              cache.writeQuery({
                query: UserChatsDocument,
                variables: {
                  variables: { limit: 20 },
                },
                data: {
                  ...result,
                  userChats: {
                    ...result.userChats,
                    edges: result.userChats.edges.filter(
                      ({ node }) => node.id !== data.leaveChat?.result?.id,
                    ),
                  },
                },
              });
            }
          }
        },
      });
    },
    clearChat({ id }: ClearChatMutationVariables) {
      return clearChat({
        variables: { id },
      });
    },
    reportChat({ id, type, comment }: ReportChatMutationVariables) {
      return reportChat({
        variables: { id, type, comment },
      });
    },
  };
};
