import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { lighten } from "@/common/services";

export const useOnboardingStyles = makeStyles<void, "coverOpetionsImage">()(
  (theme: Theme, _params, classes) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.primary.main,
      overflow: "hidden",
    },
    onboardingContainer: {
      paddingTop: "140px",
      minHeight: "100%",
      display: "grid",
      gridTemplateRows: "1fr auto",
      [theme.breakpoints.down("md")]: {
        paddingTop: "77px",
      },
    },
    stepperContainer: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "25px",
      },
    },
    background: {
      position: "fixed",
      left: 0,
      bottom: 0,
      width: "100%",
      height: 200,
      color: "white",
      textAlign: "center",
      zIndex: 999,
    },
    button: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    buttonsGrid: {
      flexDirection: "row",
      justifyContent: "flex-end",
      marginBottom: 20,
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginTop: 20,
      },
    },
    stepper: {
      backgroundColor: "transparent",
    },
    paper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: "left",
      color: theme.palette.text.secondary,
      [theme.breakpoints.up("md")]: {
        minHeight: "350px",
      },
    },
    primaryText: {
      color: "#5e6875",
    },
    topInfo: {
      marginBottom: 20,
      justifyContent: "space-between",
      alignItems: "center",
    },
    formControl: {
      width: "100%",
    },
    flexContainer: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    chip: {
      backgroundColor: lighten(theme.palette.primary.light, 0.2),
      color: theme.palette.primary.main,
    },
    activeChip: {
      color: `${theme.palette.common.white} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    spaceChildren: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-end",

      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    avatarOptionsCarousel: {
      width: 600,
      marginLeft: "auto",
      [theme.breakpoints.down("lg")]: {
        width: 450,
      },
      [theme.breakpoints.down("md")]: {
        width: 600,
        maxWidth: "100%",
        marginRight: "auto",
      },
      [theme.breakpoints.down("sm")]: {
        width: 325,
      },
      "& .slider-wrapper": {
        borderRadius: 12,
      },
      "& .slide": {
        background: "unset !important",
      },
      "& .carousel-slider": {
        overflow: "unset",
        paddingBottom: theme.spacing(5),
      },
      "& .control-dots": {
        lineHeight: "8px",
        margin: 0,
        padding: theme.spacing(1),
        "& .dot": {
          backgroundColor: "#bebec5",
          boxShadow: "none",
          opacity: 1,
          "&.selected": {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
    actionButton: {
      color: theme.palette.primary.main,
      textTransform: "none",
      fontSize: "1rem",
      fontWeight: 600,
    },
    coverOptionsCarousel: {
      width: 600,
      marginLeft: "auto",
      [theme.breakpoints.down("lg")]: {
        width: 450,
      },
      [theme.breakpoints.down("md")]: {
        width: 600,
        maxWidth: "100%",
        marginRight: "auto",
      },
      [theme.breakpoints.down("sm")]: {
        width: 300,
      },
      "& .slider-wrapper": {
        borderRadius: 12,
      },
      "& .slide": {
        background: "unset !important",
      },
      "& .carousel-slider": {
        overflow: "unset",
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
          paddingBottom: theme.spacing(8),
        },
      },
      "& .control-dots": {
        lineHeight: "1.5rem",
        margin: 0,
        padding: theme.spacing(1, 2),
        "& .dot": {
          backgroundColor: "#bebec5",
          boxShadow: "none",
          opacity: 1,
          margin: theme.spacing(0, 0.75),
          "&.selected": {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
    coverOptionsNavButton: {
      position: "absolute",
      zIndex: 2,
      top: "50%",
      backgroundColor: theme.mode.text.contrast,
      borderRadius: "50%",
      padding: theme.spacing(1.5),
      boxShadow: theme.mode.shadow.dialog,
      "&:hover": {
        backgroundColor: theme.mode.text.contrast,
        opacity: 1,
      },
      "&.right": {
        transform: `translate(50%, calc(-50% - ${theme.spacing(2.5)}))`,
        right: 0,
      },
      "&.left": {
        transform: `translate(-50%, calc(-50% - ${theme.spacing(2.5)}))`,
        left: 0,
      },
    },
    coverOpetionsImageWrapper: {
      height: 260,
      [`& > img.${classes.coverOpetionsImage}`]: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      [theme.breakpoints.down("lg")]: {
        height: 196,
      },
      [theme.breakpoints.down("md")]: {
        height: 260,
      },
      [theme.breakpoints.down("sm")]: {
        height: 130,
      },
    },
    coverOpetionsImage: {},
    coverOptionUpload: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    footer: {
      overflow: "hidden",
      marginBottom: 20,
    },
    avatarOptionsWrapper: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "32px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        justifyContent: "center",
      },
    },
    uploadAvatarWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "24px",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        justifyContent: "center",
        gap: "12px",
      },
    },
    uploadPictureLabel: {
      fontSize: "1.3125rem",
      color: theme.mode.text.description,
      [theme.breakpoints.down("md")]: {
        fontSize: "0.9375rem",
      },
    },
    uploadAvatarImage: {
      width: 80,
      height: 80,
    },
    uploadAvatarDivider: {
      padding: "40px 0px",
      textTransform: "capitalize",
      fontSize: "0.9375rem",
      fontWeight: "600",
      [theme.breakpoints.down("md")]: {
        padding: "24px 0px",
      },
    },
    createAvatarBtn: {
      width: "100%",
      justifyContent: "center",
    },
    margin: {
      margin: "0px",
    },
    createAvatarOptionWrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    },
    boxTitle: {
      fontSize: "1.0625rem",
      color: theme.mode.text.formLabel,
      fontWeight: "bold",
      lineHeight: "1.41",
    },
    pillSkeletonContainer: {
      display: "flex",
      gap: "5px",
      flexFlow: "wrap",
    },
  }),
);
