import React, { HTMLAttributes } from "react";

import { useTheme } from "@mui/material";

type TProps = HTMLAttributes<SVGSVGElement> & { usePrimary?: boolean };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CrownIcon(props: TProps) {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Web" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Web---Subscription---Banner" transform="translate(-298, -192)">
          <g
            id="Components/Organisms/Sub-bar/Default/Subscription/Active"
            transform="translate(286, 176)">
            <g id="Components/Atoms/Quiz/Active" transform="translate(12, 16)">
              <circle
                id="Oval"
                fill={props.usePrimary ? color : "#F98012"}
                cx="20"
                cy="20"
                r="20"></circle>
              <g id="Question-mark" transform="translate(5, 5)">
                <rect id="Rectangle" x="0" y="0" width="30" height="30"></rect>
                <path
                  d="M14.9688989,6.25 C15.1111603,6.25 15.2432747,6.2951703 15.3526985,6.37246904 C15.4276775,6.38787356 15.4970877,6.43310212 15.5413606,6.50368375 L19.3957075,12.6484375 L23.8660557,10.3625762 C24.0291624,10.2791735 24.216384,10.2557172 24.3950248,10.2963036 C24.8159358,10.3919328 25.0796287,10.8106714 24.9839995,11.2315824 L22.5620869,21.8916031 C22.4812376,22.24746 22.1648858,22.5 21.7999601,22.5 L8.20003987,22.5 C7.83511419,22.5 7.51876235,22.24746 7.43791313,21.8916031 L5.01600049,11.2315824 C4.92037126,10.8106714 5.18406421,10.3919328 5.60497519,10.2963036 C5.78361602,10.2557172 5.9708376,10.2791735 6.13394429,10.3625762 L10.6042925,12.6484375 L14.3304389,6.7058948 C14.4290357,6.43924485 14.6777114,6.25 14.9688989,6.25 Z"
                  id="Combined-Shape"
                  fill="#FFFFFF"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CrownIcon;
