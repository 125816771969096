import parseISO from "date-fns/parseISO";
import startOfDay from "date-fns/startOfDay";
import groupBy from "lodash/groupBy";

import { GroupByDay } from "@/chat/models";
import { MessageItemFragment, ChatFragment } from "@/graphql/types";

const groupByAuthor = (messages: MessageItemFragment[]) => {
  return messages.reduce<Array<MessageItemFragment[]>>((groups, message, index) => {
    const prevMessage = messages[index - 1];
    if (
      prevMessage?.author?.id !== message.author?.id ||
      prevMessage.__typename !== message.__typename
    ) {
      groups.push([]);
    }
    groups[groups.length - 1].push(message);
    return groups;
  }, []);
};

export const groupMessages = (messages: MessageItemFragment[]) => {
  const groupedByDay = groupBy(messages.reverse(), ({ insertedAt }) =>
    startOfDay(parseISO(insertedAt)),
  );
  return Object.keys(groupedByDay).reduce<GroupByDay[]>((prev, day) => {
    return [
      ...prev,
      {
        date: new Date(groupedByDay[day][0].insertedAt),
        messages: groupByAuthor(groupedByDay[day]),
      },
    ];
  }, []);
};

export const getBlockedAndUnagreedMembers = (chat?: ChatFragment) =>
  (chat?.chatBlocking || [])
    .filter((entity) => entity?.isBlockedByMe && !entity?.agreedBlocking)
    .map((entity) => ({
      ...entity,
      displayName: (chat?.otherMembers || []).find((user) => user?.id === entity?.userId)
        ?.displayName,
    }));

export const isEmptyOrNull = (message?: string | null | undefined) =>
  !message || message === "<p></p>";
