import { TFunction } from "react-i18next";

import {
  convertToLocalTimezone,
  distanceToNowShort,
  getUTCDateFromString,
} from "@/common/services";
import { SearchResultFragment } from "@/graphql/types";

export const renderResultTitleText = (result: SearchResultFragment): string => {
  switch (result?.__typename) {
    case "User":
      return result.displayName ?? "";
    case "Group":
    case "Debate":
    case "Event":
    case "Blog":
    case "Petition":
    case "LiveStream":
      return result.title;
    default:
      return "";
  }
};

export const renderResultSubtitleText = (
  t: TFunction<"translation">,
  result: SearchResultFragment,
) => {
  switch (result.__typename) {
    case "Group":
    case "Blog": {
      return t("Common.member_count", {
        count: result.follows.followers.count,
      });
    }
    case "Event": {
      if (result.startTime) {
        const utcStartDate = getUTCDateFromString(result.startTime);
        const startDate = convertToLocalTimezone(utcStartDate);
        return t("Generic.StartsIn") + " " + distanceToNowShort(startDate);
      } else return "";
    }
    case "Debate": {
      return t("Common.participant_count", {
        count: result.follows.followers.count,
      });
    }
    case "Petition": {
      let signaturesNeeded = 0;
      const dif = result.minimumSignaturesCount - result.activityProp.followersCount;
      if (dif >= 0) {
        signaturesNeeded = dif;
      }
      return t("Petitions.SignaturesNeeded", { count: signaturesNeeded });
    }
    case "User": {
      return t("Common.follower_count", {
        count: result.follows.followers.count,
      });
    }
    case "LiveStream": {
      if (result.startAt) {
        const utcStartDate = getUTCDateFromString(result.startAt);
        const startDate = convertToLocalTimezone(utcStartDate);
        return t("Generic.StartsIn") + " " + distanceToNowShort(startDate);
      } else return "";
    }
  }
  return "";
};

export const groupResultsByType = (
  results: SearchResultFragment[],
): {
  [key: string]: SearchResultFragment[];
} => {
  return results.reduce((acc: any, result) => {
    const type = result.__typename;

    if (type) {
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type] = [...acc[type], result];
    }

    return acc;
  }, {});
};
