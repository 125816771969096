import React from "react";

import { Theme } from "@mui/material";
import MUIChip, { ChipProps } from "@mui/material/Chip";
import { makeStyles } from "tss-react/mui";

import { lighten } from "@/common/services";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      height: "auto",
      borderRadius: 22,
      fontWeight: 600,
      padding: theme.spacing(1, 3),
    },
    clickable: {
      cursor: "pointer",
      "&:hover, &:focus": {
        backgroundColor: lighten(theme.palette.primary.light, 0.2),
        color: theme.palette.primary.main,
      },
    },
    label: {
      fontSize: "1.3125rem",
      lineHeight: "normal",
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9375rem",
      },
    },
    labelSmall: {
      fontSize: "0.9375rem",
    },
    labelMedium: {
      fontSize: "1.125rem",
    },
    deleteIcon: {
      color: theme.palette.primary.main,
    },
  };
});

const Chip = ({
  size = "normal",
  ...props
}: Omit<ChipProps, "size"> & { selected?: boolean; size?: "small" | "medium" | "normal" }) => {
  const { classes, cx } = useStyles();

  return (
    <MUIChip
      className="chip"
      classes={{
        ...classes,
        label: cx(
          classes.label,
          size === "small" && classes.labelSmall,
          size === "medium" && classes.labelMedium,
        ),
      }}
      {...props}
    />
  );
};

export default Chip;
