import { useGeoInterestsPresetsQuery } from "@/graphql/types";

export const useGeoInterestsStore = () => {
  const { data, loading } = useGeoInterestsPresetsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  return {
    loading,
    geoInterestPresets: data?.geoInterestsPresets,
  };
};
