import React, { HTMLAttributes } from "react";

import { useTheme } from "@mui/material";

const CheckCircledIcon = (
  props: HTMLAttributes<SVGSVGElement> & { checkColor?: string; backgroundColor?: string },
) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill={props.backgroundColor || color} cx="8" cy="8" r="8" />
        <path d="M3 3h10v10H3z" />
        <path
          d="M10.608 5.328 7.037 9.314 5.36 7.49a.637.637 0 0 0-.91-.03.651.651 0 0 0-.029.91l2.153 2.337c.106.117.253.19.41.206l.064.003a.639.639 0 0 0 .472-.216l4.048-4.52a.646.646 0 0 0-.335-1.085.637.637 0 0 0-.625.232z"
          fill={props.checkColor || "currentColor"}
        />
      </g>
    </svg>
  );
};

export default CheckCircledIcon;
