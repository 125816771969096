import { useRouter } from "next/router";
import React from "react";

import { Badge, IconButton, Theme, Toolbar, Tooltip } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import Hide from "@/common/components/Hide";
import Profile from "@/common/components/Profile";

import ChatIcon from "@/common/icons/ChatIcon";
import NotificationsIcon from "@/common/icons/NotificationsIcon";
import SearchIcon from "@/common/icons/SearchIcon";

// Context
import { useUnreadChatMessagesStore } from "@/chat/hooks";
import { useUnreadNotificationsCountStore } from "@/notifications/hooks";

import { AppBarActions } from "@/common/models/AppBarActions";
import { shortenedBadgeValue } from "@/common/services";
import { useFlag } from "@/feature-flags/contexts/FlagsContext";

import { palettes } from "../constants";
import NotificationsOffIcon from "../icons/NotificationsOffIcon";
import { useOneSignalContext } from "../providers";

const useStyles = makeStyles<void, "icon" | "active">()((theme: Theme, _params, classes) => ({
  badge: {
    border: "3px solid " + theme.mode.background.light,
    width: 24,
    height: 24,
    borderRadius: "50%",
    top: "5%",
    right: "14%",
    backgroundColor: palettes.red.primary.main,
  },
  searchIcon: {
    fontSize: "1.9rem",
    fill: theme.mode.icon.default,
  },
  iconBtnRoot: {
    width: 48,
    height: 46,
    borderRadius: 8,
    opacity: 1,
    [`& .${classes.icon}`]: {
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      opacity: 0.65,
    },
    "&:hover": {
      backgroundColor: theme.mode.background.default,
      opacity: 1,
    },
    [`&:hover .${classes.icon}`]: {
      opacity: 1,
    },
    [`&.${classes.active} .${classes.icon}`]: {
      opacity: 1,
    },
  },
  icon: {
    width: "1.25em",
    height: "1.25em",
    "& path": {
      fill: theme.mode.icon.default,
      color: theme.mode.icon.default,
    },
  },
  active: {
    [`& .${classes.icon} path`]: {
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}));

export type TopActionBarProps = CommonProps & {
  onActionClicked: (action: AppBarActions) => void;
  hideSearchIcon?: boolean;
};

const TopActionBar = (props: TopActionBarProps) => {
  const { unreadNotificationsCount } = useUnreadNotificationsCountStore();
  const { unreadMessagesCount } = useUnreadChatMessagesStore();
  const { classes, cx } = useStyles();
  const onClick = props.onActionClicked;
  const { enabled: chatEnabled } = useFlag("FE_CHAT");
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const { isSubscriptionOn } = useOneSignalContext();

  const isActivePath = (label: string) => {
    return label ? pathname.startsWith(`/${label}`) : false;
  };

  return (
    <Toolbar disableGutters className={props.className}>
      <Hide if={props.hideSearchIcon === true}>
        <Tooltip title={t("Main.SearchTooltip") as string}>
          <IconButton
            classes={{ root: classes.iconBtnRoot }}
            color="primary"
            aria-label="search"
            onClick={() => onClick(AppBarActions.Search)}
            size="large"
            data-cy="TopActionBar__search">
            <SearchIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Hide>
      {chatEnabled && (
        <Tooltip title={t("Main.ChatTooltip") as string}>
          <IconButton
            classes={{
              root: cx(classes.iconBtnRoot, { [classes.active]: isActivePath("chat") }),
            }}
            color="primary"
            aria-label="chat"
            onClick={() => onClick(AppBarActions.Chat)}
            size="large"
            data-cy="TopActionBar__chat">
            <Badge
              classes={{ badge: classes.badge }}
              overlap="circular"
              badgeContent={shortenedBadgeValue(unreadMessagesCount)}>
              <ChatIcon className={classes.icon} />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t("Main.NotificationsTooltip") as string}>
        <IconButton
          classes={{ root: classes.iconBtnRoot }}
          aria-label="notifications"
          onClick={() => onClick(AppBarActions.Notifications)}
          size="large"
          data-cy="TopActionBar__notifications">
          <Badge
            classes={{ badge: classes.badge }}
            overlap="circular"
            badgeContent={shortenedBadgeValue(unreadNotificationsCount)}>
            {isSubscriptionOn ? (
              <NotificationsIcon className={classes.icon} />
            ) : (
              <NotificationsOffIcon className={classes.icon} />
            )}
          </Badge>
        </IconButton>
      </Tooltip>
      <Profile></Profile>
    </Toolbar>
  );
};

export default TopActionBar;
