import { useState, useEffect } from "react";

import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { typenameToResourceType } from "@/common/constants";
import {
  SearchResultFragment,
  RemoveRecentResultMutationVariables,
  useRecentSearchesQuery,
  useSearchLazyQuery,
  useLogRecentSearchMutation,
  useRemoveRecentResultMutation,
} from "@/graphql/types";

const LIMIT = 5;

export const useSearchBarStore = () => {
  const [search, { data, loading, error }] = useSearchLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [searchStream$] = useState(new Subject<string>());

  const { data: recentSearches, loading: recentSearchesLoading } = useRecentSearchesQuery({
    fetchPolicy: "cache-first",
  });
  const [logSearch] = useLogRecentSearchMutation();
  const [removeSearchResult, { loading: removeSearchResultLoading }] =
    useRemoveRecentResultMutation();

  useEffect(() => {
    const subscription = searchStream$.pipe(debounceTime(300)).subscribe((query) => {
      return search({
        variables: {
          query,
          limit: LIMIT,
        },
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    results: data?.search?.edges?.map((edge) => edge.node) || [],
    count: data?.search.count || 0,
    hasMore: data?.search?.pageInfo?.hasNextPage,
    loading: loading || recentSearchesLoading || false,
    removeSearchResultLoading,
    error: !!error,
    search(query: string) {
      searchStream$.next(query);
    },
    recentSearches: recentSearches?.recentResults || [],
    async logRecentSearch(item: SearchResultFragment) {
      if (item.__typename) {
        switch (item.__typename) {
          case "User":
            return await logSearch({
              variables: {
                userId: item.id,
              },
            });
          case "LiveStream":
            return await logSearch({
              variables: {
                liveStreamId: item.id,
              },
            });
          default:
            return await logSearch({
              variables: {
                resourceId: item.id,
                resourceType: typenameToResourceType[item.__typename],
              },
            });
        }
      }
    },
    clearSearchResult(item?: SearchResultFragment) {
      const variables: RemoveRecentResultMutationVariables = {};
      if (item?.__typename) {
        switch (item.__typename) {
          case "User":
            variables.userId = item.id;
            break;
          case "LiveStream":
            variables.liveStreamId = item.id;
            break;
          default:
            variables.resourceId = item.id;
            variables.resourceType = typenameToResourceType[item.__typename];
            break;
        }
      }
      return removeSearchResult({
        variables,
        refetchQueries: ["RecentSearches"],
      });
    },
  };
};
