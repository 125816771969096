import { useRouter } from "next/router";
import React, { ReactNode } from "react";

import { Tooltip } from "@mui/material";

import { useTranslation } from "react-i18next";

import Link from "@/common/components/Link";
import PrimaryButton from "@/common/components/PrimaryButton";
import InterestsEditPopup from "@/profile/components/InterestsEditPopup";

import CrownBadgeIcon from "@/common/icons/CrownBadgeIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useInterests } from "@/common/hooks/use-interests";

import { Tag } from "@/graphql/types";

import { Button } from "../button/Button";
import { SubscribersOnlyFeature } from "../monetization/SubscribersOnlyFeature";
import { useNothingToShowStyles } from "./NothingToShow.styles";

export enum NothingToShowFooterElements {
  Button = "button",
  TextButton = "textbutton",
  Link = "link",
  Text = "text",
  EditInterests = "EditInterests",
  OrSeparator = "OrSeparatar",
}

export type FooterElement = { text: string } & (
  | {
      url: string;
      type?:
        | NothingToShowFooterElements.TextButton
        | NothingToShowFooterElements.Button
        | NothingToShowFooterElements.Link
        | never;
      shallow?: boolean;
      subscribersOnly?: boolean;
    }
  | { type: NothingToShowFooterElements.Text }
  | { type: NothingToShowFooterElements.EditInterests }
  | { type: NothingToShowFooterElements.OrSeparator }
);

type NothingToShowProps = {
  image: ReactNode;
  title?: string;
  subtitle: string;
  footerElements: Array<FooterElement>;
  smallHeight?: boolean;
  onEditInterestsClose?: () => void;
};

const NothingToShow = ({
  title,
  subtitle,
  image,
  footerElements,
  smallHeight,
  onEditInterestsClose,
}: NothingToShowProps) => {
  const { cx, classes } = useNothingToShowStyles();
  const { t } = useTranslation();
  const [editInterestsOpen, setEditInterestsOpen] = React.useState(false);
  const [isSubscriptionNoticeOpen, setIsSubscriptionNoticeOpen] = React.useState(false);
  const { availableGeoInterests, getGeoInterests } = useInterests();
  const { session } = useAuthenticationStore();
  const router = useRouter();

  const onEditInterests = () => {
    getGeoInterests({
      variables: {
        geographicalInterests:
          session?.user?.geographicalInterests.map((value) => value.toUpperCase()) || [],
      },
    });
    setEditInterestsOpen(true);
  };

  const handleSubscribersOnlyButton = (url: string) => {
    if (session?.user && session.user.activeSubscription) {
      router.push(url);
    } else {
      setIsSubscriptionNoticeOpen(true);
    }
  };

  const handleSubscribersOnlyModalClose = () => {
    setIsSubscriptionNoticeOpen(false);
  };

  return (
    <div className={cx(classes.wrapper, { [classes.smallHeight]: smallHeight })}>
      <SubscribersOnlyFeature
        variant="modal"
        isOpen={isSubscriptionNoticeOpen}
        onClose={() => handleSubscribersOnlyModalClose()}
      />
      <InterestsEditPopup
        open={editInterestsOpen}
        onClose={() => {
          setEditInterestsOpen(false);
          onEditInterestsClose && onEditInterestsClose();
        }}
        tags={availableGeoInterests}
        selectedTags={(session?.user?.userInterests as Tag[]) || []}
      />
      <div className={classes.image}>{image}</div>
      <h3 className={classes.title}>{title ?? t("Common.NothingToShowTitle")}</h3>
      <p className={classes.subtitle}>
        {subtitle.split("\\n").map((s) => {
          return <p key={s}>{s}</p>;
        })}
      </p>
      <nav className={classes.nav}>
        {footerElements.map((footerElement, index) => {
          if (footerElement.type === NothingToShowFooterElements.EditInterests) {
            return (
              <PrimaryButton onClick={onEditInterests} classes={{ root: classes.button }}>
                {t("Generic.EditInterests").toUpperCase()}
              </PrimaryButton>
            );
          }
          if (footerElement.type === NothingToShowFooterElements.Button) {
            return (
              <Link href={footerElement.url} passHref key={index} shallow={footerElement.shallow}>
                <PrimaryButton classes={{ root: classes.button }}>
                  {footerElement.text}
                </PrimaryButton>
              </Link>
            );
          }
          if (footerElement.type === NothingToShowFooterElements.Text) {
            return <span className={classes.footerText}>{footerElement.text}</span>;
          }
          if (footerElement.type === NothingToShowFooterElements.OrSeparator) {
            return (
              <span className={cx(classes.footerText, classes.orSeparator)}>
                {footerElement.text}
              </span>
            );
          }
          if (footerElement.type === NothingToShowFooterElements.TextButton) {
            return (
              <div className={classes.noSpacing}>
                {footerElement.subscribersOnly ? (
                  <Tooltip
                    style={{ display: "flex", marginTop: 5 }}
                    title={
                      (session?.user && !session?.user.activeSubscription
                        ? t("Monetization.Generic.callToSubscribeTooltip")
                        : "") || ""
                    }
                    arrow
                    placement="right">
                    <div>
                      <Button
                        onClick={() => handleSubscribersOnlyButton(footerElement.url)}
                        className={classes.footerTextButton}
                        variant="text">
                        {footerElement.text}&nbsp;&nbsp;
                        <CrownBadgeIcon blackBadge />
                      </Button>
                    </div>
                  </Tooltip>
                ) : (
                  <Button
                    href={footerElement.url}
                    className={classes.footerTextButton}
                    variant="text">
                    {footerElement.text}
                  </Button>
                )}
              </div>
            );
          }
          return (
            <Link key={index} href={footerElement.url} passHref shallow={footerElement.shallow}>
              <a className={classes.link}>{footerElement.text}</a>
            </Link>
          );
        })}
      </nav>
    </div>
  );
};

NothingToShow.defaultProps = {
  title: null,
};

export default NothingToShow;
