import { useEffect } from "react";

import {
  RootSubscriptionType,
  NewUnreadMessagesCountDocument,
  useGetUnreadMessagesCountQuery,
} from "@/graphql/types";

export const useUnreadChatMessagesStore = () => {
  const { data, subscribeToMore } = useGetUnreadMessagesCountQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: NewUnreadMessagesCountDocument,
      updateQuery: (prev, { subscriptionData }) => {
        const unreadMessagesCount = (subscriptionData.data as RootSubscriptionType)
          ?.newUnreadMessagesCount;

        return Object.assign({}, prev, { unreadMessagesCount });
      },
    });

    return () => unsubscribe && unsubscribe();
  }, [subscribeToMore]);

  return {
    unreadMessagesCount: data?.unreadMessagesCount || 0,
  };
};
