import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useState } from "react";

import { AppBar, Box, Toolbar, Theme, CircularProgress } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

const ConfirmDialog = dynamic<ConfirmDialogProps>(
  () =>
    import("@/common/components/ConfirmDialog/ConfirmDialog").then(
      (module) => module.ConfirmDialog,
    ),
  {
    ssr: false,
  },
);
// eslint-disable-next-line import-helpers/order-imports
import { Button } from "@/common/components/button/Button";

const useStyles = makeStyles()((theme: Theme) => ({
  appBar: {
    backgroundColor: theme.mode.background.light,
    height: "80px",
  },
  appBarColoured: {
    backgroundColor: theme.palette.primary.main,
    height: "80px",
  },
  logo: {
    height: "3em",
    fontSize: "0.875rem",
  },
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftContainer: {
    width: 196,
    marginRight: 60,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(1113)]: {
      marginRight: "0",
      flexShrink: "10",
    },
  },
  rightContainer: {
    display: "flex",
    gap: "10px",
    marginRight: 24,
  },
  logoContainer: {
    margin: theme.spacing(1),
  },
  actionBar: {
    marginRight: theme.spacing(1),
  },
  tabsContainer: {
    minWidth: 400,
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 30,
    [theme.breakpoints.down(1113)]: {
      paddingLeft: "0",
    },
  },
  searchContainer: {
    minWidth: 400,
    flexGrow: 1,
    padding: `0 ${theme.spacing(2)} 0 ${theme.spacing(1)}`,
    boxSizing: "border-box",
  },
  toolbar: {
    height: "80px",
    display: "flex",
  },
  breadcrumbs: {
    marginLeft: theme.spacing(1),
  },
  loginButton: {
    fontWeight: 800,
    fontSize: "1.065rem",
  },
  formActions: {
    display: "flex",
    gap: 48,
    paddingRight: 24,
    "& button": {
      textTransform: "uppercase",
      justifyContent: "center",
      fontSize: "0.9375rem",
      fontWeight: "800",
    },

    "& .cancel": {
      color: theme.mode.icon.light,
    },
    "& .submit": {
      minWidth: 160,
      height: 48,
    },
  },
  inForm: {
    justifyContent: "space-between",
  },
}));

import { ConfirmDialogProps } from "@/common/components/ConfirmDialog/ConfirmDialog";
import Hide from "@/common/components/Hide";
import Link from "@/common/components/Link";
import Logo from "@/common/components/Logo";
import SecondaryMainRoutesList from "@/common/components/SecondaryMainRoutesList";
import TopActionBar from "@/common/components/TopActionBar";
import { TopbarProps } from "@/common/components/TopBar";
import { useHeaderStyles } from "@/landing-page/components/header/Header.styles";
import { LanguageSwitcher } from "@/landing-page/components/language-switcher/LanguageSwitcher";
import SearchBar from "@/search/components/SearchBar/SearchBar";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useConfirmDialog } from "@/common/hooks/use-confirm-dialog";

import { AppBarActions } from "@/common/models/AppBarActions";

import { USERS_LOG_IN_URL, USERS_REGISTER_URL } from "../constants";

const TopbarDesktop = (props: TopbarProps) => {
  const { cx, classes } = useStyles();
  const { classes: classesHeader } = useHeaderStyles();
  const { breadcrumbs, showMinimal, showLanguageSwitcher } = props;
  const [searchBarOpen, setSearchBarOpen] = useState(props.searchAlwaysOpen || false);
  const { isUserLoggedIn, loading } = useAuthenticationStore();
  const { t } = useTranslation();
  const { asPath } = useRouter();
  const confirmDialogState = useConfirmDialog();
  const router = useRouter();

  const { fillLogo, topBarClass } = props.coloured
    ? { topBarClass: classes.appBarColoured, fillLogo: "#FFFFFF" }
    : { topBarClass: classes.appBar, fillLogo: null };

  const handleActionClicked = (action: AppBarActions) => {
    switch (action) {
      case AppBarActions.Search:
        setSearchBarOpen(!searchBarOpen);
        break;
      default:
        props.onActionClicked(action);
    }
  };

  const anonymousNavOptions = [
    {
      link: "/groups",
      label: t("Main.Groups"),
    },
    {
      link: "/debates",
      label: t("Main.Debates"),
    },
    {
      link: "/events",
      label: t("Main.Events"),
    },
    {
      link: "/blogs",
      label: t("Main.Blogs"),
    },
    {
      link: "/petitions",
      label: t("Main.Petitions"),
    },
    {
      link: "/livestreams",
      label: "Livestreams",
    },
  ];

  const anonymousToolbar = () => (
    <>
      <Box className={classes.container}>
        <Box className={classes.leftContainer}>
          <Link href="/">
            <a className={classes.logoContainer}>
              <Logo className={classes.logo} fill={fillLogo}></Logo>
            </a>
          </Link>
        </Box>
        {showMinimal || (
          <Box>
            <div className={classesHeader.nav}>
              <ul>
                {anonymousNavOptions.map((link, i) => {
                  return (
                    <li key={`nav_${i}`}>
                      <Link prefetch={false} href={`${link.link}`}>
                        <a>{link.label}</a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
              {showLanguageSwitcher && <LanguageSwitcher />}
            </div>
          </Box>
        )}
        {showMinimal || (
          <Box className={classes.rightContainer}>
            <Link href={{ pathname: USERS_LOG_IN_URL, query: { referralPath: asPath } }} passHref>
              <Button className={classes.loginButton} variant="text">
                {t("Session.SignUp.login")}
              </Button>
            </Link>
            <Link href={{ pathname: USERS_REGISTER_URL, query: { referralPath: asPath } }} passHref>
              <Button color="primary">{t("Session.Login.getStarted")}</Button>
            </Link>
          </Box>
        )}
      </Box>
    </>
  );

  const confirmFormCancel = () => {
    router.replace(
      {
        query: { ...router.query, action: "cancel" },
      },
      undefined,
      { shallow: true },
    );
    confirmDialogState.open({
      title: t("Common.ActivityFormCancelPrompt.title"),
      message: t("Common.ActivityFormCancelPrompt.subtitle"),
      confirmLabel: t("Common.ActivityFormCancelPrompt.leave").toUpperCase(),
      denyLabel: t("Generic.Cancel"),
      onConfirm: async () => {
        history.back();
      },
    });
  };

  return (
    <AppBar position="fixed" elevation={0} classes={{ root: topBarClass }}>
      <Toolbar
        className={cx(classes.toolbar, { [classes.inForm]: !!props.formId })}
        disableGutters
        data-cy="toolbar">
        {loading || (
          <>
            <Hide if={!isUserLoggedIn || showMinimal}>
              <Box className={classes.leftContainer}>
                <Link href="/">
                  <a className={classes.logoContainer}>
                    <Logo className={classes.logo} fill={fillLogo}></Logo>
                  </a>
                </Link>
              </Box>

              <Hide if={searchBarOpen || !!props.formId}>
                <Box className={classes.tabsContainer}>
                  <Hide if={!!breadcrumbs}>{props.tabs}</Hide>

                  <Hide if={!breadcrumbs}>
                    <SecondaryMainRoutesList />
                  </Hide>
                </Box>
              </Hide>

              <Hide if={!(searchBarOpen || props.searchAlwaysOpen) || !!props.formId}>
                <Box className={classes.searchContainer}>
                  <SearchBar
                    closeButtonClicked={() => setSearchBarOpen(props.searchAlwaysOpen || false)}
                    alwaysOpen={props.searchAlwaysOpen}
                  />
                </Box>
              </Hide>
              {!props.formId && (
                <>
                  <TopActionBar
                    className={classes.actionBar}
                    onActionClicked={handleActionClicked}
                    hideSearchIcon={searchBarOpen}
                  />
                </>
              )}
            </Hide>
            <Hide if={isUserLoggedIn && !showMinimal}>{anonymousToolbar()}</Hide>
          </>
        )}
        <Hide if={!props.formId}>
          <div className={classes.formActions}>
            <Button className="cancel" onClick={confirmFormCancel} variant="text">
              {t("Generic.Cancel")}
            </Button>
            <Button
              className="submit"
              form={props.formId}
              type="submit"
              disabled={props.isCreateButtonDisabled}>
              {props.isActionLoading ? <CircularProgress size={18} /> : props.actionButtonText}
            </Button>
          </div>
          <ConfirmDialog dialogState={confirmDialogState} />
        </Hide>
      </Toolbar>
    </AppBar>
  );
};

export default TopbarDesktop;
