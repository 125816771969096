import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

import { AppBar, Box, IconButton, Toolbar, Badge, CircularProgress } from "@mui/material";

import { useTranslation } from "react-i18next";

import { ConfirmDialogProps } from "@/common/components/ConfirmDialog/ConfirmDialog";
import Link from "@/common/components/Link";
import PrimaryButton from "@/common/components/PrimaryButton";
import { TopbarProps } from "@/common/components/TopBar";
import { useHeaderStyles } from "@/landing-page/components/header/Header.styles";
import { MobileNavDialog } from "@/landing-page/components/header/MobileNavDialog";
import { LanguageSwitcher } from "@/landing-page/components/language-switcher/LanguageSwitcher";
import type { SearchDialogProps } from "@/search/components/SearchDialog/SearchDialog";

import { LogoSmall } from "@/common/icons/LogoSmall";
import NotificationsIcon from "@/common/icons/NotificationsIcon";
import NotificationsOffIcon from "@/common/icons/NotificationsOffIcon";
import SearchIcon from "@/common/icons/SearchIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useConfirmDialog } from "@/common/hooks/use-confirm-dialog";
import useDetectPlatform from "@/common/hooks/use-detect-platform";
import { useUnreadNotificationsCountStore } from "@/notifications/hooks";

import { USERS_REGISTER_URL } from "@/common/constants";
import { AppBarActions } from "@/common/models/AppBarActions";
import { useOneSignalContext, useRoutedDialog } from "@/common/providers";
import { shortenedBadgeValue } from "@/common/services";

import { Button } from "../button/Button";
import useStyles from "./TopBarMobile.styles";

const SearchDialog = dynamic<SearchDialogProps>(
  () =>
    import("@/search/components/SearchDialog/SearchDialog").then((module) => module.SearchDialog),
  {
    ssr: false,
  },
);
const ConfirmDialog = dynamic<ConfirmDialogProps>(
  () =>
    import("@/common/components/ConfirmDialog/ConfirmDialog").then(
      (module) => module.ConfirmDialog,
    ),
  {
    ssr: false,
  },
);

export const TopbarMobile = ({
  onActionClicked,
  showMinimal,
  formId,
  isCreateButtonDisabled,
  actionButtonText,
  headingLabel,
  isActionLoading,
  showLanguageSwitcher,
}: TopbarProps) => {
  const { classes, cx } = useStyles();
  const { classes: classesHeader } = useHeaderStyles();

  const { t } = useTranslation();
  const router = useRouter();
  const routedDialog = useRoutedDialog();
  const { isUserLoggedIn, loading } = useAuthenticationStore();
  const { unreadNotificationsCount } = useUnreadNotificationsCountStore();
  const confirmDialogState = useConfirmDialog();
  const [open, setOpen] = React.useState(false);
  const { isSubscriptionOn } = useOneSignalContext();
  const { handleRedirectToStore } = useDetectPlatform();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const searchClicked = () => {
    routedDialog.open("search");
    onActionClicked(AppBarActions.Search);
  };

  const handleRegister = () => {
    handleRedirectToStore();
    router.push(USERS_REGISTER_URL);
  };

  const confirmFormCancel = () => {
    router.replace(
      {
        query: { ...router.query, action: "cancel" },
      },
      undefined,
      { shallow: true },
    );
    confirmDialogState.open({
      title: t("Common.ActivityFormCancelPrompt.title"),
      message: t("Common.ActivityFormCancelPrompt.subtitle"),
      confirmLabel: t("Common.ActivityFormCancelPrompt.leave").toUpperCase(),
      denyLabel: t("Generic.Cancel"),
      onConfirm: async () => {
        history.back();
      },
    });
  };

  const navObject = [
    {
      link: "/groups",
      label: t("Main.Groups"),
    },
    {
      link: "/debates",
      label: t("Main.Debates"),
    },
    {
      link: "/events",
      label: t("Main.Events"),
    },
    {
      link: "/blogs",
      label: t("Main.Blogs"),
    },
    {
      link: "/petitions",
      label: t("Main.Petitions"),
    },
    {
      link: "/livestreams",
      label: "Livestreams",
    },
  ];

  return (
    <>
      <AppBar elevation={0} classes={{ root: classes.appBar }}>
        <Toolbar classes={{ root: classes.toolbar }}>
          {loading ||
            (isUserLoggedIn && !showMinimal ? (
              <>
                {formId ? (
                  <div className={classes.formToolbar}>
                    <Button className="left" onClick={confirmFormCancel} variant="text">
                      {t("Generic.Cancel")}
                    </Button>
                    <span className="center">{headingLabel}</span>
                    <Button
                      className="right"
                      form={formId}
                      type="submit"
                      variant="text"
                      disabled={isCreateButtonDisabled}>
                      {isActionLoading ? (
                        <CircularProgress size={18} />
                      ) : (
                        actionButtonText?.toUpperCase()
                      )}
                    </Button>
                    <ConfirmDialog dialogState={confirmDialogState} />
                  </div>
                ) : (
                  <>
                    <IconButton
                      className={classes.button}
                      edge="end"
                      color="primary"
                      aria-label="search"
                      onClick={searchClicked}
                      size="large">
                      <SearchIcon className={classes.actionIcon} />
                    </IconButton>
                    <Link href="/">
                      <LogoSmall className={classes.logo} />
                    </Link>
                    <IconButton
                      className={classes.button}
                      edge="start"
                      color="primary"
                      aria-label="notifications"
                      href="/notifications"
                      size="large">
                      <Badge
                        badgeContent={shortenedBadgeValue(unreadNotificationsCount)}
                        color="secondary"
                        overlap="circular"
                        classes={{ badge: classes.badge }}>
                        {isSubscriptionOn ? (
                          <NotificationsIcon
                            className={cx(classes.actionIcon, {
                              [classes.actionActive]: router.pathname === "/notifications",
                            })}
                          />
                        ) : (
                          <NotificationsOffIcon
                            className={cx(classes.actionIcon, {
                              [classes.actionActive]: router.pathname === "/notifications",
                            })}
                          />
                        )}
                      </Badge>
                    </IconButton>
                  </>
                )}
              </>
            ) : (
              <>
                <Box className={classes.leftContainer}>
                  <Link href="/">
                    <LogoSmall className={classes.logo} />
                  </Link>
                </Box>
                {showMinimal || (
                  <div className={classes.actionBar}>
                    {showLanguageSwitcher && (
                      <div className="language">
                        <LanguageSwitcher />
                      </div>
                    )}

                    <PrimaryButton
                      onClick={() => handleRegister()}
                      className={classes.getStartedButton}>
                      {t("Profile.GetStarted")}
                    </PrimaryButton>
                  </div>
                )}
                <div
                  className={cx(
                    classesHeader.mobileMenuIcon,
                    classesHeader.mobileMenuIconAnonMargin,
                  )}>
                  <Image
                    onClick={handleClickOpen}
                    src="/images/landing-page/hamburger.svg"
                    height="24"
                    width="24"
                  />
                </div>
                {open && (
                  <MobileNavDialog onClose={handleClose} navObject={navObject} open={open} />
                )}
              </>
            ))}
        </Toolbar>
      </AppBar>

      {routedDialog.isOpen("search") ? <SearchDialog {...routedDialog.bind("search")} /> : null}
    </>
  );
};
