import { useRouter } from "next/router";
import React from "react";

import { IconButton, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import Link from "@/common/components/Link";

import { mainRoutes } from "@/common/services";

const useStyles = makeStyles<void, "active" | "icon">()((theme: Theme, _props, classes) => ({
  menuListItem: {
    borderRadius: 26,
    margin: "12px 18px",
    width: "auto",
    paddingLeft: 4,
    paddingRight: 4,
    "&:hover": {
      backgroundColor: theme.mode.background.default,
    },
  },
  linkWrapper: {
    width: "2.875rem",
    height: "2.875rem",
    borderRadius: 8,
    opacity: 0.65,
    margin: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.mode.background.default,
      opacity: 1,
    },
    [`&.${classes.active}`]: {
      opacity: 1,
    },
    [`&.${classes.active} .${classes.icon}`]: {
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  active: {},
  iconButtonLabel: {
    height: "100%",
    "&:hover": {
      opacity: 1,
    },
  },
  icon: {
    width: "1.25em",
    height: "1.25em",
    fill: theme.mode.icon.default,
    color: theme.mode.icon.default,
  },
}));

function SecondaryMainRoutesList() {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const router = useRouter();
  const isActivePath = (label: string) => {
    return label ? router.pathname.startsWith(`/${label}`) : false;
  };

  return (
    <>
      {mainRoutes.map((route, i) => (
        <Tooltip title={t(route.label) as string} key={i} arrow>
          <div className={cx(classes.linkWrapper, { [classes.active]: isActivePath(route.id) })}>
            <Link href={route.link} passHref>
              <IconButton
                className={classes.iconButtonLabel}
                color="primary"
                aria-label={t(route.label)}
                size="large">
                <route.icon className={classes.icon} />
              </IconButton>
            </Link>
          </div>
        </Tooltip>
      ))}
    </>
  );
}

export default SecondaryMainRoutesList;
