import { useEffect, useRef } from "react";

type Draft = {
  chatId: string;
  value?: string;
  imageIds?: string[];
  fileIds?: string[];
  videoId?: string;
};

const getChatMessageDrafts = () => {
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem("chatMessageDrafts");
    return saved ? JSON.parse(saved) : [];
  }
};

const setChatMessageDrafts = (drafts: Draft[]) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("chatMessageDrafts", JSON.stringify(drafts));
  }
};

export const useChatMessageDraftStore = (chatId: string) => {
  const messageDraft: Draft =
    getChatMessageDrafts().find((draft: Draft) => draft.chatId === chatId) || {};
  const messageValue = useRef<string>(messageDraft.value || "");
  useEffect(() => {
    setChatMessageDrafts(getChatMessageDrafts().filter((draft: Draft) => draft.chatId !== chatId));

    return () => {
      if (messageValue.current.length) {
        setChatMessageDrafts([
          ...getChatMessageDrafts(),
          {
            chatId,
            value: messageValue.current,
          },
        ]);
      }
    };
  }, []);

  return {
    messageValueDraft: messageDraft.value,
    setMessageValue(value: string) {
      messageValue.current = value;
    },
    clearDraft() {
      messageValue.current = "";
    },
  };
};
