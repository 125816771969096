import { useRouter } from "next/router";
import React, { useState } from "react";

import { IconButton, Menu, MenuItem, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useApolloClient } from "@apollo/client";
import app from "firebase.js";
import { getAuth, signOut } from "firebase/auth";
import { useTranslation } from "react-i18next";

import Link from "@/common/components/Link";

import FilledArrowIcon from "@/common/icons/FilledArrowIcon";

import { useAuthenticationStore } from "@/authentication/hooks";

import { useSubscriptionProvider } from "@/monetization/providers/SubscriptionProvider";

import UserAvatar from "./UserAvatar";

const useStyles = makeStyles()((theme: Theme) => ({
  avatar: {
    marginLeft: theme.spacing(0.5),
    width: 40,
    height: 40,
    cursor: "pointer",
    backgroundColor: "#BDBDBD",
  },
  paper: {
    top: "80px !important",
    minWidth: "150px",
    backgroundColor: theme.mode.background.light,
  },
  icon: {
    width: "1.5em",
    height: "1.5em",
    fill: theme.mode.icon.default,
    color: theme.mode.icon.default,
  },
  iconOpen: {
    transform: "rotate(180deg)",
  },
}));

const Profile = () => {
  const { cx, classes } = useStyles();
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { signOut: nonSmSignout, session } = useAuthenticationStore();
  const apollo = useApolloClient();
  const auth = getAuth(app);
  const subscriptionProvider = useSubscriptionProvider();

  const handleClick = (event: any) => {
    setMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  const onLogout = async () => {
    await signOut(auth).catch(() => {});
    await nonSmSignout().catch(() => {});
    await apollo.resetStore().catch(() => {});
    router.push("/");
  };

  const onSubscribe = () => {
    handleClose();
    subscriptionProvider.open();
  };

  return (
    <>
      <Link href={`/profiles/${session?.user?.slug}`}>
        <a>
          <UserAvatar
            className={classes.avatar}
            user={session?.user}
            data-cy="TopbarProfile__toggle"
            defaultAvatar={session?.user?.initials}
          />
        </a>
      </Link>
      <IconButton
        size="small"
        color="primary"
        disableRipple={true}
        data-cy="TopbarProfile__menu-toggle"
        onClick={handleClick}>
        <FilledArrowIcon
          className={cx(classes.icon, { [classes.iconOpen]: menuOpen })}
          direction={menuOpen ? "top" : "bottom"}
        />
      </IconButton>
      <Menu
        classes={{ paper: classes.paper }}
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <Link href="/settings" passHref>
          <MenuItem data-cy="ProfileMenu__settings">{t("Main.Settings")}</MenuItem>
        </Link>
        <Link href="/invite-friends" passHref>
          <MenuItem>{t("Main.InviteFriends")}</MenuItem>
        </Link>
        {session?.user.activeSubscription || (
          <MenuItem onClick={onSubscribe}>{t("Generic.Subscribe")}</MenuItem>
        )}
        {/* <MenuItem onClick={onDonate}>{t("Generic.Subscribe")}</MenuItem> */}
        <Link href="/donate/" passHref>
          <MenuItem data-cy="ProfileMenu__Donate">{t("Monetization.Donations.Donate")}</MenuItem>
        </Link>
        <Link href="/help" passHref>
          <MenuItem data-cy="ProfileMenu__HelpAndSupport">{t("Main.HelpAndSupport")}</MenuItem>
        </Link>
        <MenuItem data-cy="ProfileMenu__logout" onClick={onLogout}>
          {t("Main.Logout")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Profile;
