import React from "react";

import UserAvatar from "@/common/components/UserAvatar";

import ArticlesIcon from "@/common/icons/ArticlesIcon";
import CalendarIcon from "@/common/icons/CalendarIcon";
import ConversationIcon from "@/common/icons/ConversationIcon";
import GroupsIcon from "@/common/icons/GroupsIcon";
import PetitionsIcon from "@/common/icons/PetitionsIcon";
import VideoIcon from "@/common/icons/VideoIcon";

import { SearchResultFragment } from "@/graphql/types";

import useStyles from "./ResultItemIcon.styles";

export const ResultItemIcon = ({ item }: { item: SearchResultFragment }) => {
  const classes = useStyles();

  const iconsProps = {
    className: classes.icon,
  };

  switch (item.__typename) {
    case "Group":
      return <GroupsIcon {...iconsProps} />;
    case "Debate":
      return <ConversationIcon {...iconsProps} />;
    case "Event":
      return <CalendarIcon {...iconsProps} />;
    case "Blog":
      return <ArticlesIcon {...iconsProps} />;
    case "Petition":
      return <PetitionsIcon {...iconsProps} />;
    case "User":
      return <UserAvatar user={item} hidePresence classes={{ root: classes.avatar }} />;
    case "LiveStream":
      return <VideoIcon {...iconsProps} />;
    default:
      return null;
  }
};
