import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useLanguageSwitcherStyles = makeStyles()((theme: Theme) => {
  return {
    languageSwitcherMenuRoot: {
      gap: "4px",
      [theme.breakpoints.down("md")]: {
        marginRight: 16,
      },
    },
    mobileMenuIcon: {
      height: 24,
      width: 24,
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    menuItemContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    menuItemLabel: {
      fontSize: "0.8125rem",
      color: "#3f3d56",
      fontWeight: "800",
      textTransform: "uppercase",
    },
    menuPaper: {
      width: "unset",
    },
    languageIconGlobe: {
      color: "#3f3d56",
    },
  };
});
