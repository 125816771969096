import React, { HTMLAttributes } from "react";

const CloseIcon = (props: HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-914 -368) translate(470 336) translate(436 24) translate(8 8)"
                />
                <path
                  fill="currentColor"
                  d="M6.052 4.352L12 10.3l5.948-5.948c.47-.47 1.23-.47 1.7 0s.47 1.23 0 1.7L13.7 12l5.948 5.948c.47.47.47 1.23 0 1.7s-1.23.47-1.7 0L12 13.7l-5.948 5.948c-.47.47-1.23.47-1.7 0s-.47-1.23 0-1.7L10.3 12 4.352 6.052c-.47-.47-.47-1.23 0-1.7s1.23-.47 1.7 0z"
                  transform="translate(-914 -368) translate(470 336) translate(436 24) translate(8 8)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
