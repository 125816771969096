import React from "react";

type FilledArrowIconProps = { [key: string]: unknown } & {
  direction?: "top" | "right" | "bottom" | "left";
};

function FilledArrowIcon(props: FilledArrowIconProps) {
  const { direction = "bottom" } = props;
  const rotationMap: { [key: string]: number } = {
    bottom: 0,
    left: 90,
    top: 180,
    right: 270,
  };
  const style = { transform: `rotate(${rotationMap[direction]}deg)` };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      style={style}>
      <g>
        <path
          fill="currentColor"
          d="M12 17c-.517-.003-1.005-.238-1.33-.64l-4.21-5.1c-.502-.626-.603-1.484-.26-2.21.278-.631.9-1.042 1.59-1.05h8.42c.69.008 1.312.419 1.59 1.05.343.726.242 1.584-.26 2.21l-4.21 5.1c-.325.402-.813.637-1.33.64z"
        />
      </g>
    </svg>
  );
}

export default FilledArrowIcon;
