import { Theme, darken } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useHeaderStyles = makeStyles()((theme: Theme) => {
  return {
    hasAnouncement: {
      flexDirection: "column",
    },
    innerWithAnouncement: {
      [theme.breakpoints.down(1100)]: {
        width: "100%",
      },
    },
    container: {
      display: "flex",
      background: "rgba(255,255,255,0.9)",
      backdropFilter: "saturate(180%) blur(20px)",
      zIndex: "99",
      boxShadow: "0 4px 32px 0 rgba(63, 61, 86, 0.08)",
      position: "fixed",
      width: "100%",
    },
    containerInner: {
      width: "1175px",
      [theme.breakpoints.down(1250)]: {
        width: "unset",
      },
      margin: "0 auto",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(3, 0),
      [theme.breakpoints.down(1250)]: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    left: {},
    center: {},
    right: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(3),
      [theme.breakpoints.down("md")]: {
        gap: "0px",
      },
    },
    logo: {
      fontSize: "0.875rem",
      marginLeft: "-6px",
    },
    nav: {
      display: "flex",
      alignItems: "center",
      gap: "24px",
      [theme.breakpoints.down(1100)]: {
        display: "none",
      },
      "& ul": {
        display: "flex",
        gap: "1rem",
      },
      "& ul li": {
        textTransform: "uppercase",
        fontSize: "0.8125rem",
        color: "#3f3d56",
        fontWeight: "800",
      },
    },
    buttons: {
      display: "flex",
      gap: theme.spacing(2),
      [theme.breakpoints.down(1100)]: {
        display: "none",
      },
    },
    btnLogin: {
      width: "120px",
      height: "40px",
      fontWeight: "800",
      display: "inline-flex",
      fontSize: "0.8125rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      justifyContent: "center",
      minHeight: "unset",
    },
    btnSignup: {
      width: "120px",
      height: "40px",
      fontWeight: "800",
      display: "inline-flex",
      fontSize: "0.8125rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      justifyContent: "center",
      minHeight: "unset",
      backgroundColor: "white",
      border: "2px solid #f98012",
      color: "#f98012",
      "&:hover": {
        backgroundColor: "white !important",
        color: darken("#f98012", 0.05),
        borderColor: darken("#f98012", 0.05),
      },
    },
    languageSwitcher: {},
    mobileMenuIcon: {
      marginLeft: "-5px",
      height: 24,
      width: 24,
      [theme.breakpoints.up(1100)]: {
        display: "none",
      },
    },
    mobileMenuIconAnonMargin: {
      marginLeft: "15px",
    },
    mobileDialogRoot: {
      borderRadius: 0,
    },
    mobileDialogContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "31px",
      padding: "40px 24px",
      color: "#3f3d56",
      fontSize: "1.3125rem",
      fontWeight: "800",
      textTransform: "uppercase",
    },
    mobileDialogClose: {
      height: 38,
      width: 38,
      backgroundColor: "#F0F0F0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
    },
    offsetNZ: {
      marginRight: "-100px",
    },
  };
});
