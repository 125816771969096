import {
  GetNotificationsDocument,
  GetNotificationsQuery,
  NotificationFragment,
  GetUnreadNotificationsCountDocument,
  useGetNotificationsLazyQuery,
  useMarkAllNotificationAsReadMutation,
  useClearAllNotificationsMutation,
  useAcceptFollowRequestMutation,
  useMarkNotificationAsReadMutation,
  useRejectFollowRequestMutation,
  useAcceptInviteMutation,
  useRejectInviteMutation,
  Invitable,
  useGetSystemNotificationsLazyQuery,
  useAnswerNotificationMutation,
  useClearNotificationMutation,
} from "@/graphql/types";

const ITEMS_FETCH_LIMIT = 10;

export const useNotificationsStore = () => {
  const [fetchNotifications, { data, fetchMore, loading: notificationsLoading }] =
    useGetNotificationsLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });

  const [
    fetchSystemNotifications,
    {
      data: systemNotificationData,
      fetchMore: fetchMoreSystemNotifications,
      loading: systemNotificationsLoading,
    },
  ] = useGetSystemNotificationsLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [markAllAsRead] = useMarkAllNotificationAsReadMutation();
  const [clearAll] = useClearAllNotificationsMutation();
  const [clearNotification] = useClearNotificationMutation();
  const [markAsRead] = useMarkNotificationAsReadMutation();
  const [acceptFollow, { loading: acceptFollowLoading }] = useAcceptFollowRequestMutation();
  const [rejectFollow, { loading: rejectFollowLoading }] = useRejectFollowRequestMutation();
  const [acceptInvite, { loading: acceptInviteLoading }] = useAcceptInviteMutation();
  const [rejectInvite, { loading: rejectInviteLoading }] = useRejectInviteMutation();
  const [answerNotification, { loading: answerNotificationLoading }] =
    useAnswerNotificationMutation();

  return {
    notifications: data?.notifications.edges.map(({ node }) => node),
    systemNotifications: systemNotificationData?.systemNotifications.edges.map(({ node }) => node),
    hasMorePages: data?.notifications.pageInfo.hasNextPage,
    hasMoreSystemNotificationPages:
      systemNotificationData?.systemNotifications.pageInfo.hasNextPage,
    totalNotificationsCount: data?.notifications.count || 0,
    totalSystemNotificationsCount: systemNotificationData?.systemNotifications.count || 0,
    loadingFollowAction: acceptFollowLoading || rejectFollowLoading,
    loadingInviteAction: acceptInviteLoading || rejectInviteLoading,
    clearNotification(id: string) {
      return clearNotification({
        variables: { id },
        update(cache) {
          const normalizedId = cache.identify({
            id: id,
            __typename: "SystemNotification",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
    fetchSystemNotifications() {
      return fetchSystemNotifications({
        variables: { limit: ITEMS_FETCH_LIMIT },
      });
    },
    fetchNotifications() {
      return fetchNotifications({
        variables: { limit: ITEMS_FETCH_LIMIT },
      });
    },
    fetchNextSystemNotificationsPage() {
      if (systemNotificationData && fetchMoreSystemNotifications) {
        return fetchMoreSystemNotifications({
          variables: {
            after: systemNotificationData.systemNotifications.pageInfo.endCursor,
          },
        });
      }
    },
    fetchNextNotificationsPage() {
      if (data && fetchMore) {
        return fetchMore({
          variables: {
            after: data.notifications.pageInfo.endCursor,
          },
        });
      }
    },
    answerNotification(id: string, answer: boolean) {
      return answerNotification({
        variables: {
          id: id,
          action_value: answer,
        },
      });
    },
    markAllAsRead() {
      return new Promise((resolve, reject) => {
        markAllAsRead({
          update(cache, { data }) {
            if (data?.readAllNotifications?.successful) {
              const result = cache.readQuery<GetNotificationsQuery>({
                query: GetNotificationsDocument,
              });

              if (result) {
                cache.writeQuery({
                  query: GetNotificationsDocument,

                  data: {
                    ...result,
                    notifications: {
                      ...result.notifications,
                      edges: result.notifications.edges.map((edge) => ({
                        ...edge,
                        node: { ...edge.node, read: true },
                      })),
                    },
                  },
                });
              }

              cache.writeQuery({
                query: GetUnreadNotificationsCountDocument,
                data: {
                  unreadNotificationsCount: 0,
                },
              });
            }
          },
        }).then(({ data }) => {
          if (data?.readAllNotifications?.successful) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    clearAll() {
      return clearAll({
        update(cache, { data }) {
          if (data?.clearAllNotifications?.successful) {
            cache.evict({ id: "ROOT_QUERY", fieldName: "notifications" });
            cache.writeQuery({
              query: GetUnreadNotificationsCountDocument,
              data: {
                unreadNotificationsCount: 0,
              },
            });
          }
        },
      });
    },
    markAsRead(notification: NotificationFragment) {
      return markAsRead({
        variables: {
          id: notification.id,
        },
        optimisticResponse: {
          readNotification: {
            __typename: "NotificationPayload",
            successful: true,
            result: {
              id: notification.id,
              read: true,
            },
            messages: [],
          },
        },
        refetchQueries: ["GetUnreadNotificationsCount"],
      });
    },
    acceptFollow(notificationId: string, followId: string) {
      return acceptFollow({
        variables: {
          id: followId,
        },
        refetchQueries: ["GetUnreadNotificationsCount"],
        update(cache, { data }) {
          if (data?.acceptFollowRequest?.successful) {
            const result = cache.readQuery<GetNotificationsQuery>({
              query: GetNotificationsDocument,
            });

            if (result) {
              cache.writeQuery({
                query: GetNotificationsDocument,
                data: {
                  ...result,
                  notifications: {
                    ...result.notifications,
                    edges: result.notifications.edges.map((edge) => {
                      if (edge.node.id === notificationId) {
                        return {
                          ...edge,
                          node: { ...edge.node, answered: true },
                        };
                      }
                      return edge;
                    }),
                  },
                },
              });
            }
          }
        },
      });
    },
    rejectFollow(notificationId: string, followId: string) {
      return rejectFollow({
        variables: {
          id: followId,
        },
        refetchQueries: ["GetUnreadNotificationsCount"],
        update(cache, { data }) {
          if (data?.rejectFollowRequest?.successful) {
            const result = cache.readQuery<GetNotificationsQuery>({
              query: GetNotificationsDocument,
            });

            if (result) {
              cache.writeQuery({
                query: GetNotificationsDocument,
                data: {
                  ...result,
                  notifications: {
                    ...result.notifications,
                    edges: result.notifications.edges.filter(
                      (edge) => edge.node.id !== notificationId,
                    ),
                  },
                },
              });
            }
          }
        },
      });
    },
    acceptInvite(invitableId: string, notificationId: string, invitableType: Invitable) {
      return acceptInvite({
        variables: {
          invitableId: invitableId,
          notificationId: notificationId,
          invitableType: invitableType,
        },
        refetchQueries: ["GetUnreadNotificationsCount"],
        update(cache, { data }) {
          if (data?.acceptInviteRequest?.successful) {
            const result = cache.readQuery<GetNotificationsQuery>({
              query: GetNotificationsDocument,
            });

            if (result) {
              cache.writeQuery({
                query: GetNotificationsDocument,
                data: {
                  ...result,
                  notifications: {
                    ...result.notifications,
                    edges: result.notifications.edges.map((edge) => {
                      if (edge.node.id === notificationId) {
                        return {
                          ...edge,
                          node: { ...edge.node, answered: true },
                        };
                      }
                      return edge;
                    }),
                  },
                },
              });
            }
          }
        },
      });
    },
    rejectInvite(notificationId: string) {
      return rejectInvite({
        variables: {
          notificationId: notificationId,
        },
        refetchQueries: ["GetUnreadNotificationsCount"],
        update(cache, { data }) {
          if (data?.rejectInviteRequest?.successful) {
            const result = cache.readQuery<GetNotificationsQuery>({
              query: GetNotificationsDocument,
            });

            if (result) {
              cache.writeQuery({
                query: GetNotificationsDocument,
                data: {
                  ...result,
                  notifications: {
                    ...result.notifications,
                    edges: result.notifications.edges.filter(
                      (edge) => edge.node.id !== notificationId,
                    ),
                  },
                },
              });
            }
          }
        },
      });
    },
    notificationsLoading,
    systemNotificationsLoading,
    answerNotificationLoading,
  };
};
