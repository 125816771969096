import { useState } from "react";

import { ConfirmDialogStateInternal, ConfirmDialogConfig } from "@/common/models/ConfirmDialog";

export const useConfirmDialog = () => {
  const [state, setState] = useState({
    open: false,
    title: "",
    message: "",
    disable2fa: false,
    isConfirmationActionLoading: false,
    onClose: () => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onConfirm: (args?: any) => {},
    onDeny: () => {},
  } as ConfirmDialogStateInternal);

  return {
    open(config: ConfirmDialogConfig) {
      setState({
        ...config,
        open: true,
      });
    },
    close() {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
    },
    setLoadingConfirmationAction() {
      setState((prevState) => ({
        ...prevState,
        isConfirmationActionLoading: true,
      }));
    },
    internalState: state,
  };
};
