import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

import LanguageIcon from "@mui/icons-material/Language";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useTranslation } from "react-i18next";

import { useLanguageSwitcherStyles } from "./LanguageSwitcher.styles";

export const LanguageSwitcher = () => {
  const { classes } = useLanguageSwitcherStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t, i18n } = useTranslation();
  const router = useRouter();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (lang: string) => {
    setAnchorEl(null);
    changeLanguage(lang);
  };
  const changeLanguage = async (lng: string) => {
    try {
      await i18n.changeLanguage(lng);
      await router.push(router.pathname, router.pathname, { locale: lng });
    } catch {}
  };

  return (
    <div>
      <Button
        id="language-switcher"
        classes={{ root: classes.languageSwitcherMenuRoot }}
        aria-controls={open ? "language-switcher-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}>
        <LanguageIcon className={classes.languageIconGlobe} />
        <span className={classes.menuItemLabel}>{i18n.language}</span>
        <Image
          src="/images/landing-page/language-switcher/chevron-down.svg"
          width={16}
          height={16}
        />
      </Button>
      <Menu
        id="language-switcher-menu"
        PopoverClasses={{ paper: classes.menuPaper }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={{
          "aria-labelledby": "language-switcher",
        }}>
        <MenuItem onClick={() => handleClose("en")}>
          <div className={classes.menuItemContainer}>
            <span className={classes.menuItemLabel}>{t("Languages.en")}</span>
          </div>
        </MenuItem>
        <MenuItem onClick={() => handleClose("fr")}>
          <div className={classes.menuItemContainer}>
            <span className={classes.menuItemLabel}>{t("Languages.fr")}</span>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};
