import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    padding: theme.spacing(1),
    background: theme.palette.primary.main,
    borderRadius: "50%",
    fill: theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText,
    width: 32,
    height: 32,
    flexShrink: 0,
    [theme.breakpoints.down("md")]: {
      width: 24,
      height: 24,
      fill: theme.mode.text.disabled,
      color: theme.mode.text.disabled,
      background: "unset",
      borderRadius: 0,
      padding: 0,
    },
  },
  avatar: {
    width: 32,
    height: 32,
    [theme.breakpoints.down("md")]: {
      width: 24,
      height: 24,
    },
  },
}));

export default useStyles;
