import { useState, useEffect } from "react";

import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { ChatUsersQueryVariables, useChatUsersLazyQuery } from "@/graphql/types";

export const useChatUsersStore = (chatId?: string) => {
  const [fetch, { data, fetchMore, networkStatus }] = useChatUsersLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const [searchStream$] = useState(new Subject<string | undefined>());

  useEffect(() => {
    const subscription = searchStream$.pipe(debounceTime(300)).subscribe((query) => {
      return fetch({
        variables: {
          query,
          limit: 10,
          chatId,
        },
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    initialLoading: networkStatus === 1,
    users: data?.chatUsers?.edges?.map(({ node }) => node) || [],
    fetchNextPage: (variables: Partial<ChatUsersQueryVariables>) => {
      if (fetchMore && data) {
        return fetchMore({
          variables: {
            after: data.chatUsers.pageInfo.endCursor,
            ...variables,
          },
        });
      }
    },
    fetch: ({ query }: Pick<ChatUsersQueryVariables, "query">) => {
      if (query) {
        return searchStream$.next(query || undefined);
      }
      return fetch({
        variables: {
          query,
          limit: 10,
          chatId,
        },
      });
    },
    hasMorePages: data?.chatUsers.pageInfo.hasNextPage || false,
  };
};
