import React from "react";

function NotificationsIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M19.581 14.886l-1.602-1.629V9.244c.044-3.082-2.166-5.725-5.178-6.192-1.71-.228-3.435.3-4.733 1.45C6.77 5.65 6.024 7.31 6.02 9.055v4.202l-1.602 1.63c-.411.422-.532 1.053-.307 1.6.225.549.753.907 1.34.91H8.44v.306c.086 1.902 1.678 3.375 3.559 3.294 1.881.081 3.473-1.392 3.56-3.294v-.306h2.989c.586-.003 1.114-.361 1.34-.91.224-.547.103-1.178-.308-1.6zm-5.801 2.817c-.103.9-.887 1.558-1.78 1.494-.893.064-1.677-.595-1.78-1.494v-.306h3.56v.306z" />
      </g>
    </svg>
  );
}

export default NotificationsIcon;
