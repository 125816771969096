import { useGetInterestsQuery, useGeoInterestsLazyQuery } from "@/graphql/types";

export const useInterests = () => {
  const { data, loading, refetch } = useGetInterestsQuery();
  const [getGeoInterests, { data: geoInterestsData, loading: geoInterestsLoading }] =
    useGeoInterestsLazyQuery();

  const [
    getCachedGeoInterests,
    { data: cachedGeoInterestsData, loading: cachedGeoInterestsLoading },
  ] = useGeoInterestsLazyQuery({
    fetchPolicy: "cache-first",
  });

  return {
    refetch,
    loading,
    availableInterests: data?.interests || [],
    availableGeoInterests: geoInterestsData?.geoInterests || [],
    geoInterestsLoading,
    getGeoInterests,
    cachedGeoInterestsLoading,
    getCachedGeoInterests,
    availableCachedGeoInterests: cachedGeoInterestsData?.geoInterests || [],
  };
};
