import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { Dialog } from "@mui/material";

import { useTranslation } from "react-i18next";

import CrownIcon from "@/common/icons/CrownIcon";

import { useSubscriptionProvider } from "@/monetization/providers/SubscriptionProvider";

import { Button } from "../button/Button";
import useStyles from "./SubscribersOnlyFeature.styles";

type TProps = {
  isOpen?: boolean;
  variant: "page" | "modal";
  onClose?: () => void;
};
export const SubscribersOnlyFeature = ({ isOpen, variant, onClose }: TProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const router = useRouter();
  const subscriptionProvider = useSubscriptionProvider();

  const { payment_intent_client_secret, redirect_status } = router.query;
  const [isPlanSelectorOpen, setIsPlanSelectorOpen] = useState(false);

  useEffect(() => {
    if (payment_intent_client_secret && redirect_status && !isPlanSelectorOpen) {
      setIsPlanSelectorOpen(true);
    }
  }, [payment_intent_client_secret]);

  const handleSubscribe = () => {
    subscriptionProvider.open();
    if (variant === "modal") {
      onClose && onClose();
    }
  };

  return variant === "page" ? (
    <div className={classes.container}>
      <div className="center">
        <div className="icon">
          <CrownIcon usePrimary />
        </div>
        <div className="title">{t("Monetization.Subscriptions.Livestream.EmptyPage.Title")}</div>
        <div className="subtitle">
          <span>{t("Monetization.Subscriptions.Livestream.EmptyPage.Subtitle")}</span>
        </div>
        <div className="cta">
          <Button variant="text" onClick={() => handleSubscribe()}>
            {t("Monetization.Subscriptions.Subscribe").toUpperCase()}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <Dialog
      open={isOpen || false}
      classes={{ paper: classes.dialog }}
      onClose={() => onClose && onClose()}
      data-cy="SubscribersOnlyFeatureModal">
      <>
        <div className={classes.container}>
          <div className="center">
            <div className="icon">
              <CrownIcon usePrimary />
            </div>
            <div className="title">
              {t("Monetization.Subscriptions.Livestream.EmptyPage.Title")}
            </div>
            <div className="subtitle">
              <span>{t("Monetization.Subscriptions.Livestream.EmptyPage.Subtitle")}</span>
            </div>
            <div className="cta">
              <Button variant="text" onClick={() => handleSubscribe()}>
                {t("Monetization.Subscriptions.Subscribe").toUpperCase()}
              </Button>
            </div>
          </div>
        </div>
      </>
    </Dialog>
  );
};
