import { useEffect } from "react";

import {
  useGetUnreadNotificationsCountQuery,
  RootSubscriptionType,
  NewUnreadNotificationsCountDocument,
} from "@/graphql/types";

export const useUnreadNotificationsCountStore = () => {
  const { data, subscribeToMore } = useGetUnreadNotificationsCountQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: NewUnreadNotificationsCountDocument,
      updateQuery: (prev, { subscriptionData }) => {
        const unreadNotificationsCount = (subscriptionData.data as RootSubscriptionType)
          ?.newUnreadNotificationsCount;

        return Object.assign({}, prev, { unreadNotificationsCount });
      },
    });

    return () => unsubscribe && unsubscribe();
  }, [subscribeToMore]);

  return {
    unreadNotificationsCount: data?.unreadNotificationsCount || 0,
  };
};
