import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { palettes } from "@/common/constants/palettes";

export default makeStyles()((theme: Theme) => ({
  appBar: {
    backgroundColor: theme.mode.background.light,
  },
  logo: {
    height: 40,
  },
  logoContainer: {
    fontSize: "0.875rem",
  },
  button: {
    height: "auto",
    color: "#aaaaaa",
  },
  toolbar: {
    minHeight: "77px",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
  },
  searchContainer: {
    minWidth: 100,
    flexGrow: 1,
  },
  leftContainer: {
    width: 196,
    display: "flex",
    alignItems: "center",
  },
  actionBar: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",

    "& .language": {
      minWidth: 100,
    },
  },
  actionIcon: {
    width: "2rem",
    height: "2rem",
    fill: "#BEBEC5",
    color: "#BEBEC5",
  },
  actionActive: {
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  badge: {
    border: "3px solid " + theme.mode.background.light,
    width: 24,
    height: 24,
    borderRadius: "50%",
    top: "14%",
    right: "14%",
    background: palettes.red.primary.main,
  },
  getStartedButton: {
    height: 30,
    fontSize: 12,
    whiteSpace: "nowrap",
    minWidth: "100px !important",
  },
  formToolbar: {
    display: "flex",
    flexGrow: "1",
    alignItems: "center",

    "& .center": {
      color: theme.mode.text.heading,
      width: "100%",
      textAlign: "center",
      fontSize: "0.9375rem",
      fontWeight: "800",
      textTransform: "capitalize",
    },
    "& button:disabled": {
      color: theme.mode.icon.light,
      opacity: 1,
    },
    "& button:nth-child(3):not(:disabled)": {
      color: `${theme.palette.primary.main} !important`,
    },
    "& .left": {
      color: theme.mode.icon.light,
    },
    "& button": {
      fontSize: "0.9375rem",
      fontWeight: "800",
    },
  },
}));
