import React from "react";

function SearchIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path
          d="M20.704 19.285l-3.399-3.39c1.097-1.396 1.692-3.121 1.69-4.898 0-4.416-3.58-7.997-7.998-7.997C6.581 3 3 6.58 3 10.997s3.58 7.998 7.997 7.998c1.777.002 3.502-.593 4.899-1.69l3.389 3.4c.187.189.443.295.71.295.266 0 .522-.106.71-.296.189-.187.295-.443.295-.71 0-.266-.106-.522-.296-.71zM5 10.997C5 7.685 7.685 5 10.997 5c3.313 0 5.998 2.686 5.998 5.998 0 3.313-2.685 5.998-5.998 5.998C7.685 16.995 5 14.31 5 10.997z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default SearchIcon;
