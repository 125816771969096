import React, { useEffect } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import Chip from "@/common/components/Chip";
import { PillSkeletons } from "@/onboarding/components/OnboardingStep1";

import { useInterests } from "@/common/hooks/use-interests";

const useStyles = makeStyles()((theme: Theme) => ({
  interestChips: {
    display: "flex",
    flexWrap: "wrap",
    gap: 12,
    marginTop: 24,

    "& .chip > span": {
      fontSize: "0.9375rem",
    },
  },
  hint: {
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9375rem",
    },
  },
}));

export const EmptySearchPlaceholder = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { getCachedGeoInterests, availableCachedGeoInterests } = useInterests();

  useEffect(() => {
    getCachedGeoInterests({
      variables: { geographicalInterests: ["US", "FR", "NZ"] },
    });
  }, []);

  return (
    <>
      <div>
        <span className={classes.hint}>{t("App.Search.Empty.Hint")}</span>
        <div className={classes.interestChips}>
          {availableCachedGeoInterests.length === 0 && <PillSkeletons numberOfPills={23} />}
          {availableCachedGeoInterests
            .slice()
            .sort((a, b) => {
              var nameA = t(a.translationKey).toLowerCase();
              var nameB = t(b.translationKey).toLowerCase();
              return nameA.localeCompare(nameB);
            })
            .map((interest) => (
              <Chip color="primary" key={interest.name} label={t(interest.translationKey)} />
            ))}
        </div>
      </div>
    </>
  );
};
