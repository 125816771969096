import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useCommonStyles = makeStyles()((theme: Theme) => ({
  inputLabel: {
    color: "#5e6875",
    fontWeight: "bold",
    marginBottom: 5,
  },
  primaryText: {
    color: theme.mode.text.heading,
    fontSize: "2.25rem",
    fontWeight: 800,
    lineHeight: "1.22",
    paddingBottom: theme.spacing(2),
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3125rem",
    },
  },
  secondaryText: {
    color: theme.palette.primary.main,
    fontSize: "1.3125rem",
    lineHeight: "normal",
    fontWeight: "bold",
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0625rem",
    },
  },
  introText: {
    fontSize: "1.0625rem",
    color: theme.mode.text.description,
    paddingBottom: theme.spacing(2),
    margin: 0,
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9375rem",
    },
  },
  center: {
    textAlign: "center",
  },
}));

export default useCommonStyles;
