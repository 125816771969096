import { useEffect, useState } from "react";

interface Value {
  value: string;
  text: string;
}

export const useSelectableTags = (
  collection: Value[],
  activeValues: Value[],
  { min = 0 }: { min?: number } = {},
) => {
  const [items, setItems] = useState<{ value: string; text: string; active: boolean }[]>(
    collection.map(({ value, text }) => {
      return {
        value: value,
        text: text,
        active: !!activeValues.find(({ value: activeVal }) => activeVal === value),
      };
    }),
  );

  useEffect(() => {
    setItems(
      collection.map(({ value, text }) => {
        return {
          value: value,
          text: text,
          active: !!activeValues.find(({ value: activeVal }) => activeVal === value),
        };
      }),
    );
  }, [JSON.stringify(collection), JSON.stringify(activeValues)]);

  const activeItems = items.reduce((acc, curr) => {
    if (curr.active) {
      acc.push(curr.value);
    }
    return acc;
  }, [] as string[]);

  const toggle = (itemValue: string) => {
    setItems((prev) => {
      let newActiveLength = 0;
      const newSelected = prev.map((item) => {
        const { value, active } = item;

        if (value === itemValue) {
          !active && newActiveLength++;
          return { ...item, active: !active };
        }

        active && newActiveLength++;
        return item;
      });

      return newActiveLength >= min ? newSelected : prev;
    });
  };

  return {
    items,
    activeItems,
    toggle,
  };
};
