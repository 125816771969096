import { useRouter } from "next/router";
import { useEffect } from "react";

import { groupMessages } from "@/chat/services";
import {
  MessageInput,
  MessageSentDocument,
  MessageUpdatedDocument,
  RootSubscriptionType,
  ChatMessagesQuery,
  useChatMessagesQuery,
  useSendMessageMutation,
  useUpdateLastSeenMessageMutation,
  ChatFragment,
  MessageItemFragment,
  useReportChatMessageMutation,
  ReportChatMessageMutationVariables,
} from "@/graphql/types";

export const useChatMessagesStore = (chatId: string, chat?: ChatFragment) => {
  const {
    loading,
    data: messagesData,
    subscribeToMore: subscribeToMoreChatMessages,
    fetchMore,
    refetch,
  } = useChatMessagesQuery({
    variables: { chatId, limit: 20 },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data: ChatMessagesQuery) => {
      console.log("oncomplete", data);
      const messageId = data.allChatMessages.edges
        ?.filter((edge) => edge?.node.__typename === "Message")
        .map((edge) => edge?.node.id)[0];
      if (messageId) {
        updateLastSeenMessage({
          variables: { chatId, messageId },
        });
      }
    },
  });
  const [sendMessage, { loading: loadingSendMessage, data: sendMessageData }] =
    useSendMessageMutation();
  const [updateLastSeenMessage] = useUpdateLastSeenMessageMutation();
  const [reportChatMessage] = useReportChatMessageMutation();

  const router = useRouter();
  useEffect(() => {
    let unsubscribeMessageSent: () => void | undefined;
    let unsubscribeMessageUpdated: () => void | undefined;

    if (!chat?.isSingleChatBlocked) {
      unsubscribeMessageSent = subscribeToMoreChatMessages({
        document: MessageSentDocument,
        variables: { chatId },
        updateQuery: (prev, { subscriptionData }) => {
          const newMessage = (subscriptionData.data as RootSubscriptionType)?.messageSent;
          const exist = prev.allChatMessages.edges?.find(
            (edge) => edge?.node.id === newMessage?.id,
          );
          if (!newMessage || exist) return prev;
          if (newMessage.__typename === "Message") {
            updateLastSeenMessage({ variables: { chatId, messageId: newMessage.id } });
          }

          return Object.assign({}, prev, {
            allChatMessages: {
              ...prev.allChatMessages,
              edges: [
                {
                  __typename: "MessageItemEdge",
                  node: newMessage,
                },
                ...(prev.allChatMessages.edges || []),
              ],
            },
          });
        },
      });

      unsubscribeMessageUpdated = subscribeToMoreChatMessages({
        document: MessageUpdatedDocument,
        variables: { chatId },
      });
    }

    return () => {
      unsubscribeMessageSent && unsubscribeMessageSent();
      unsubscribeMessageUpdated && unsubscribeMessageUpdated();
    };
  }, [chat?.isSingleChatBlocked]);

  useEffect(() => {
    if (router.query.reconnectChat === "1") {
      const refetchFn = async () => {
        await refetch();
      };
      refetchFn();
      const unsubscribeMessageSent = subscribeToMoreChatMessages({
        document: MessageSentDocument,
        variables: { chatId },
        updateQuery: (prev, { subscriptionData }) => {
          const newMessage = (subscriptionData.data as RootSubscriptionType)?.messageSent;
          const exist = prev.allChatMessages.edges?.find(
            (edge) => edge?.node.id === newMessage?.id,
          );
          if (!newMessage || exist) return prev;
          if (newMessage.__typename === "Message") {
            updateLastSeenMessage({ variables: { chatId, messageId: newMessage.id } });
          }

          return Object.assign({}, prev, {
            allChatMessages: {
              ...prev.allChatMessages,
              edges: [
                {
                  __typename: "MessageItemEdge",
                  node: newMessage,
                },
                ...(prev.allChatMessages.edges || []),
              ],
            },
          });
        },
      });

      const unsubscribeMessageUpdated = subscribeToMoreChatMessages({
        document: MessageUpdatedDocument,
        variables: { chatId },
      });

      const { pathname, query } = router;
      delete router.query.reconnectChat;
      router.replace({ pathname, query }, undefined, { shallow: true });

      return () => {
        unsubscribeMessageSent && unsubscribeMessageSent();
        unsubscribeMessageUpdated && unsubscribeMessageUpdated();
      };
    }
  }, [router.query.reconnectChat]);

  return {
    loading,
    loadingSendMessage,
    sendMessageData,
    messages: groupMessages(
      messagesData?.allChatMessages.edges?.map((edge) => edge?.node as MessageItemFragment) || [],
    ),
    messagesCount: messagesData?.allChatMessages.edges?.length || 0,
    lastMessage: messagesData?.allChatMessages.edges
      ? messagesData?.allChatMessages.edges[0]?.node
      : undefined,
    hasMoreMessages: messagesData?.allChatMessages.pageInfo.hasNextPage || false,
    refetch() {
      refetch && refetch();
    },
    fetchNextMessagesPage() {
      if (fetchMore && messagesData) {
        return fetchMore({
          variables: {
            after: messagesData.allChatMessages.pageInfo.endCursor,
          },
        });
      }
    },
    sendMessage: async ({
      chatId,
      repliedMessageId,
      body,
      imageIds,
      fileIds,
      videoId,
      embedUrls,
    }: MessageInput) => {
      const parts: Array<Omit<MessageInput, "chatId">> = [
        { body, embedUrls },
        { imageIds },
        { videoId },
        ...(fileIds?.map((fileId) => ({ fileIds: [fileId] })) || []),
      ].filter((part) => Object.values(part)[0]?.length);

      return parts.reduce(async (prev, part) => {
        await prev;

        await sendMessage({
          variables: {
            data: {
              chatId,
              repliedMessageId,
              body: null,
              ...part,
            },
          },
        });
      }, Promise.resolve());
    },
    reportChatMessage({ id, type, comment }: ReportChatMessageMutationVariables) {
      return reportChatMessage({
        variables: { id, type, comment },
      });
    },
  };
};
