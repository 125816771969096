import React, { HTMLAttributes } from "react";

const NotificationsOffIcon = (props: HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="m6.103 10 1.176 1.09 6.805 6.328.064.06 1.195 1.16.546.51.111.061c-.306 1.67-1.894 2.864-3.703 2.788-1.958.078-3.614-1.339-3.704-3.168v-.295h-3.11c-.606-.013-1.145-.361-1.372-.886a1.352 1.352 0 0 1 .325-1.529l1.667-1.566V10.51c-.01-.17-.01-.34 0-.511zm-1.4-5.968a.948.948 0 0 1 .915.246l1.614 1.614L8.62 7.279l8.986 8.996 1.888 1.888 1.227 1.217a.944.944 0 0 1 0 1.34.944.944 0 0 1-1.34 0l-2.55-2.557-.556-.557-1.265-1.265-.066-.066-6.9-6.89L6.392 7.78 4.278 5.618a.948.948 0 0 1 .425-1.586zm9.445 14.502h-3.703v.295c.107.865.923 1.498 1.852 1.436.929.062 1.745-.571 1.851-1.436v-.295zM8.497 4.285c1.248-.986 2.91-1.438 4.557-1.24 2.904.397 5.036 2.658 4.995 5.297v3.433l1.542 1.393c.401.359.52.901.3 1.37-.086.18-.218.338-.386.462l-.266-.23-1.713-1.54-8.164-7.336-1.25-1.124L8 4.7a5.7 5.7 0 0 1 .497-.415z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default NotificationsOffIcon;
